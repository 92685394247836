
	import { defineComponent } from 'vue';
	import { IonIcon, modalController } from '@ionic/vue';
	import { useI18n } from 'vue-i18n';
	import { pieChartOutline, hardwareChipOutline, cartOutline, mapOutline, videocamOutline, personOutline, ticketOutline, documentTextOutline, statsChartOutline, cashOutline, calendarOutline, notificationsOutline, cogOutline } from 'ionicons/icons';
	import PosModal from '@/components/PosModal.vue'
	export default defineComponent({
		name: 'SideMenu',
		components: { IonIcon },
		setup() {
			const { t } = useI18n({
				inheritLocale: true,
				useScope: 'global'
			});
			const openPOS = async () => {
				const modal = await modalController.create({
					component: PosModal,
					cssClass: 'my-custom-class',
					componentProps: {
						transaction: null
					}
				});
				return modal.present();
			};
			return {
				pieChartOutline,
				hardwareChipOutline,
				mapOutline,
				cartOutline,
				videocamOutline,
				personOutline,
				ticketOutline,
				documentTextOutline,
				statsChartOutline,
				cashOutline,
				calendarOutline,
				notificationsOutline,
				cogOutline,
				t,
				openPOS
			}
		}
	});
