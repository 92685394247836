// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import AuthService from '../services/auth.service';
import UserService from '../services/user.service';
const stored_used = localStorage.getItem('user');
//@ts-ignore
const _user = stored_used == null ? false : JSON.parse(localStorage.getItem('user'));
const initialState = _user ? { status: { loggedIn: true }, user: _user, forgot:null } : { status: { loggedIn: false }, user: null, forgot:null };

export const user = {
	namespaced: true,
	state: initialState,
	actions: {
        //@ts-ignore
		get({ commit }) {
			return UserService.get().then(
				result => {
					commit('getUserSuccess', result);
					return Promise.resolve(result);
				},
				error => {
					commit('getUserFailure');
					return Promise.reject(error);
				}
			);
		},
        //@ts-ignore
		saveFCM({ commit }, fcmToken) {
			return AuthService.saveFCM(fcmToken).then(
				result => {
					commit('saveFcmSuccess', result);
					return Promise.resolve(result);
				},
				error => {
					commit('saveFcmFailure');
					return Promise.reject(error);
				}
			);
		}
	},
	mutations: {
        //@ts-ignore
		getUserSuccess(state, account) {
			// state.user = forgot;
		},
        //@ts-ignore
		getUserFailure(state) {
			// state.forgot = null;
		},
        //@ts-ignore
		saveFCMSuccess(state, account) {
			// state.user = forgot;
		},
        //@ts-ignore
		saveFCMFailure(state) {
			// state.forgot = null;
		}
	}
};