// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import moment from 'moment-timezone';
import { utf8ToAnsi } from 'utf8-to-ansi';
import { useI18n } from 'vue-i18n';
import ThermalPrinter from './Thermal';
import Timezone from './Timezone';
import ConfigService from '../services/config.service';

export default class Printer
{
    $store = null;
    $translate = null;
    Printer_USBDevices = [];
    Printer_lastUsedUSBDevice = null;
    constructor(store, translate)
    {
        this.$store     = store;
        this.$translate = translate;
    }

    async BindListeners()
    {
        //@ts-ignore
        if (typeof navigator.usb != 'undefined') {
            //@ts-ignore
            navigator.usb.addEventListener('connect', event => {
                // Add event.device to the UI.
                const preferedUSBDevice = localStorage.getItem('prefered_coupon_usb');
                if (preferedUSBDevice != null &&
                    //@ts-ignore
                    preferedUSBDevice == device.vendorId + '_' + device.productId + (typeof device.serialNumber != 'undefined' ? '_' + device.serialNumber : '')) {
                    this.Printer_lastUsedUSBDevice = event.device;
                }
            });
            //@ts-ignore
            navigator.usb.addEventListener('disconnect', event => {
                if (this.Printer_lastUsedUSBDevice == event.device) {
                    this.Printer_lastUsedUSBDevice = null;
                }
            });
            //On load...
            await this.RefreshUSBDevices();
            const preferedUSBDevice = localStorage.getItem('prefered_coupon_usb');
            for(let i = 0; i < this.Printer_USBDevices.length; i++) {
                const device = this.Printer_USBDevices[i];
                if (preferedUSBDevice != null &&
                    //@ts-ignore
                    preferedUSBDevice == device.vendorId + '_' + device.productId + (typeof device.serialNumber != 'undefined' ? '_' + device.serialNumber : '')) {
                        this.Printer_lastUsedUSBDevice = device;
                }
            }
        }
    }

    SetPreferedUSBDevice(device)
    {
        const preferedUSBDevice = device.vendorId + '_' + device.productId + (typeof device.serialNumber != 'undefined' ? '_' + device.serialNumber : '');
        localStorage.setItem('prefered_coupon_usb', preferedUSBDevice);
    }

    async GetSystemLogo()
    {
        try
        {
            const response = await ConfigService.list('general');
            if(response.success)
            {
                const config = response.configs.filter((x) => x.name == 'logo');
                if (config.length == 1 && config[0].value != '') {
                    return config[0].value;
                }
            }
        }
        catch(error)
        {
            console.error(error);
        }
        return false;
    }

    async GetCouponText(locale)
    {
        try
        {
            const response = await ConfigService.list('general');
            if(response.success)
            {
                const config = response.configs.filter((x) => x.name == 'coupontxt' + locale);
                if (config.length == 1 && config[0].value != '') {
                    return config[0].value;
                }
            }
        }
        catch(error)
        {
            console.error(error);
        }
        return '';
    }

    async PrintAccessCard(access_card, locale)
    {
        try
        {
            const translate = this.$translate;
            const coupontxt = utf8ToAnsi(await this.GetCouponText(locale));
            const device    = await this.GetUSBDevice();
            const thermal   = new ThermalPrinter(device.manufacturerName, { width: 42 });
            thermal.alignCenter();
            const logo      = await this.GetSystemLogo();
            await thermal.printImage(logo ? logo : '/assets/logo.png');
            thermal.newLine();
            thermal.alignLeft();
            //@ts-ignore
            thermal.print(utf8ToAnsi(translate('access_card')) + ' - ' + moment().utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('YYYY-MM-DD HH:mm a'));
            thermal.newLine();
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('max_vehicles')) + ":", access_card.max_entrance);
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('validation_date')) + ":", moment(access_card.validation_date).utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('YYYY-MM-DD'));
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('expiration_date')) + ":", moment(access_card.expiration_date).utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('YYYY-MM-DD'));
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('between_hours_start')) + ":", moment(access_card.between_hours_start).utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('HH:mm a'));
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('between_hours_end')) + ":", moment(access_card.between_hours_end).utcOffset(Timezone.getTimezone(this.$store.state.auth.user)).format('HH:mm a'));
            let days = '';
            //@ts-ignore
            if(access_card.allow_monday)    days = utf8ToAnsi(translate('day_2'));
            //@ts-ignore
            if(access_card.allow_tuesday)   days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_3'));
            //@ts-ignore
            if(access_card.allow_wednesday) days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_4'));
            //@ts-ignore
            if(access_card.allow_thursday)  days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_5'));
            //@ts-ignore
            if(access_card.allow_friday)    days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_6'));
            //@ts-ignore
            if(access_card.allow_saturday)  days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_7'));
            //@ts-ignore
            if(access_card.allow_sunday)    days += (days == '' ? '' : ', ') + utf8ToAnsi(translate('day_1'));
            //@ts-ignore
            thermal.leftRight(utf8ToAnsi(translate('allowed_days')) + ": ", days);
            //
            thermal.newLine();
            thermal.newLine();
            thermal.alignCenter();
            if(access_card.type == '1')
            {
                thermal.printQR('lexohparkingqr:' + access_card.username, {
                    cellSize: 8, // 1 - 8
                    correction: 'H', // L(7%), M(15%), Q(25%), H(30%)
                    model: 2
                })
            }
            else if(access_card.type == '2')
            {
                thermal.code128(access_card.username, {
                    height: 80, // 50 < x < 80
                    hriPos: 2
                });
                // thermal.printBarcode(access_card.username, 69, {
                // 	height: 80, // 50 < x < 80
                // 	hriPos: 2
                // });
            }
            thermal.newLine();
            //@ts-ignore
            thermal.print(utf8ToAnsi(translate('nip')) + ' - ' + access_card.username);

            thermal.newLine();
            thermal.alignLeft();
            thermal.print(coupontxt);
            thermal.cut();
            const buffer = thermal.getOutput();
            return await this.PrintBufferViaUSB(buffer, device);
        }
        catch(error)
        {
            console.error(error);
        }
        return false;
    }

    async RefreshUSBDevices()
    {
        //@ts-ignore
        if (typeof navigator.usb != 'undefined') this.Printer_USBDevices = await navigator.usb.getDevices();
        return this.Printer_USBDevices;
    }

    async GetUSBDevice()
    {
        //@ts-ignore
        if (typeof navigator.usb == 'undefined') return null;
        if (this.Printer_lastUsedUSBDevice != null) {
            //@ts-ignore
            this.Printer_USBDevices = await navigator.usb.getDevices();
            //@ts-ignore
            if (this.Printer_USBDevices.filter((device) => device.vendorId == this.Printer_lastUsedUSBDevice.vendorId && device.productId == this.Printer_lastUsedUSBDevice.productId && (typeof this.Printer_lastUsedUSBDevice.serialNumber != 'undefined' ? device.serialNumber == this.Printer_lastUsedUSBDevice.serialNumber : true)).length > 0) {
                return this.Printer_lastUsedUSBDevice;
            }
        }
        //@ts-ignore
        return await navigator.usb.requestDevice({filters:[]});
    }

    async PrintBufferViaUSB(buffer, device)
    {
        try
        {
            //@ts-ignore
            if (typeof navigator.usb == 'undefined') return false;
            if (typeof device == 'undefined' || device == null || device == false) {
                device = await this.GetUSBDevice();
            }
            if (device.opened != true) await device.open();
            console.log('opened device!');
            const { interfaceNumber } = device.configuration.interfaces[0];
            await device.claimInterface(interfaceNumber);
            console.log('claimed interface:', interfaceNumber);
            const outpoint = device.configuration.interfaces[0].alternate.endpoints.find(ep => ep.direction == 'out');
            await device.transferOut(outpoint.endpointNumber, buffer);
            console.log('transfered to:', outpoint.endpointNumber);
            await device.close();
            console.log('closed device!');
            this.Printer_lastUsedUSBDevice = device;
            return true;
        }
        catch(error)
        {
            this.Printer_lastUsedUSBDevice = null;
            console.error(error);
        }
        return false;
    }
}