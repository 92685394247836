
	import { defineComponent } from 'vue';
	import { modalController, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonInput } from '@ionic/vue';
    import { useI18n } from 'vue-i18n';
    let translate;
	export default  defineComponent({
		name: 'PayModal',
		components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonItem, IonInput },
        props: ['data', 'transaction_id', 'amount_paid', 'total_amount'],
		setup()
		{
			const closeModal = () => {
				modalController.dismiss();
			};
			const { t } = useI18n({
				inheritLocale: true,
				useScope: 'global'
			});
            translate = t;
			return { closeModal, t };
		},
        data()
		{
			return {
                rounded_amount:    '0.00',
				cash_amount:       '0.00',
                card_amount:       '0.00',
                amount_to_be_paid: '0.00',
                cash_back:         '0.00'
            }
        },
        created()
        {
            this.rounded_amount = (Math.ceil((this.total_amount - this.amount_paid)*20 - 0.5)/20).toFixed(2)
        },
        watch: {
            'cash_amount': {
				handler: async function(newValue) {
                    if (newValue == '') {
                        this.cash_amount = '0.00';
                        return;
                    }
                    this.calculateAmounts(parseFloat(newValue), parseFloat(this.card_amount), true);
				},
				deep: true
			},
            'card_amount': {
				handler: async function(newValue) {
                    if (newValue == '') {
                        this.card_amount = '0.00';
                        return;
                    }
                    this.calculateAmounts(parseFloat(this.cash_amount), parseFloat(newValue), false);
				},
				deep: true
			}
		},
        methods: {
            number_format(n: number, c: number, d: string, t: string)
            {
                c = isNaN(c = Math.abs(c)) ? 2 : c;
                d = d == undefined ? "." : d;
                t = t == undefined ? "," : t;
                var s = n < 0 ? "-" : "",
                //@ts-ignore
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;
                //@ts-ignore
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            },
            onCashClicked()
            {
                if (this.cash_amount == '0.00' && this.card_amount == '0.00') {
                    this.cash_amount = this.rounded_amount;
                }
            },
            onCardClicked()
            {
                if (this.cash_amount == '0.00' && this.card_amount == '0.00') {
                    this.card_amount = this.number_format(this.total_amount - this.amount_paid, 2, '.', '');
                    // this.cash_amount = '0.00';
                }
            },
            addCashAmount(amount)
            {
                this.cash_amount = this.number_format(parseFloat(this.cash_amount) +  amount, 2, '.', '');
            },
            calculateAmounts(cash, card, editedCash)
            {
                const total_to_be_paid = (this.total_amount - this.amount_paid);
                const paying_amount    = cash + card;
                //
                if (paying_amount >= total_to_be_paid && cash > 0 && card > 0) {
                    this.cash_back = '0.00';
                    if(cash > total_to_be_paid) {
                        this.card_amount = '0.00';
                        return;
                    } else if (card > total_to_be_paid) {
                        this.card_amount = this.number_format(total_to_be_paid, 2, '.', '');
                        this.cash_amount = '0.00';
                        return;
                    } else {
                        this.card_amount = this.number_format(total_to_be_paid - cash, 2, '.', '');
                        return;
                    }
                } else if(paying_amount < total_to_be_paid && cash > 0) {
                    this.card_amount = this.number_format(total_to_be_paid - cash, 2, '.', '');
                    return;
                }
                //
                this.amount_to_be_paid = this.number_format(paying_amount, 2, '.', '');
                //
                const difference = total_to_be_paid - paying_amount;
                if (difference < 0) {
                    this.cash_back = this.number_format(difference * -1, 2, '.', '');
                } else {
                    this.cash_back = '0.00';
                }
            }
        },
        computed: {
			darkMode() {
				return document.body.classList.contains('dark');
			}
		}
    });
