// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import AuthService from '../services/auth.service';

const stored_used = localStorage.getItem('user');
//@ts-ignore
const user = stored_used == null ? false : JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user, forgot:null } : { status: { loggedIn: false }, user: null, forgot:null };

export const auth = {
	namespaced: true,
	state: initialState,
	actions: {
		//@ts-ignore
		login({ commit }, user) {
			return AuthService.login(user.username, user.password).then(
				user => {
					commit('loginSuccess', user);
					return Promise.resolve(user);
				},
				error => {
					commit('loginFailure');
					return Promise.reject(error);
				}
			);
		},
		//@ts-ignore
		async logout({ commit }) {
			try
			{
				await AuthService.logout();
			}
			catch(e) { console.log(e); }
			commit('logout');
		},
		//@ts-ignore
		register({ commit }, user) {
			return AuthService.register(user).then(
				user => {
					commit('registerSuccess', user);
					return Promise.resolve(user);
				},
				error => {
					return Promise.reject(error);
				}
			);
		},
		//@ts-ignore
		saveFCM({ commit }, fcmToken) {
			return AuthService.saveFCM(fcmToken).then(
				result => {
					commit('saveFcmSuccess', result);
					return Promise.resolve(result);
				},
				error => {
					commit('saveFcmFailure');
					return Promise.reject(error);
				}
			);
		}
	},
	mutations: {
		//@ts-ignore
		loginSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		//@ts-ignore
		loginFailure(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		//@ts-ignore
		logout(state) {
			state.status.loggedIn = false;
			state.user = null;
		},
		//@ts-ignore
		registerSuccess(state, user) {
			state.status.loggedIn = true;
			state.user = user;
		},
		//@ts-ignore
		registerFailure(state) {
			state.status.loggedIn = false;
		},
		//@ts-ignore
		saveFCMSuccess(state, account) {
			// state.user = forgot;
		},
		//@ts-ignore
		saveFCMFailure(state) {
			// state.forgot = null;
		}
	}
};