
    import { defineComponent } from 'vue';
    import IosContrastIcon from 'vue-ionicons/dist/ios-contrast.vue'
    import IosRadioButtonOffIcon from 'vue-ionicons/dist/ios-radio-button-off.vue'
    import UserService from '../services/user.service';
    import { useI18n } from 'vue-i18n'
    export default defineComponent({
        name: 'HeaderPage',
        components: { IosContrastIcon, IosRadioButtonOffIcon },
        setup()
        {
            setTimeout(() => {
                const shouldAdd = document.body.classList.contains('dark');
                document.getElementById('buttonToggleModeDark')?.classList.toggle('active', shouldAdd);
                document.getElementById('buttonToggleModeLight')?.classList.toggle('active', !shouldAdd);
            }, 10);
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            return { t };
        },
        data()
        {
            return {
                isDarkMode: false
            }
        },
        mounted()
        {
            this.isDarkMode = document.body.classList.contains('dark');
            //@ts-ignore
            UserService.setRouter(this.$router);
            //@ts-ignore
            UserService.setStore(this.$store);
            // var userInterval = setInterval(async function()
            // {
            //     var result = await UserService.online();
            //     if (result == null || typeof result == 'string') {
            //         clearInterval(userInterval);
            //     }
            // }, 1000);
        },
        methods: {
            switchLocale()
            {
                //@ts-ignore
                localStorage.setItem('locale', (this.$root.$i18n.locale == 'fr' ? 'en' : 'fr'));
                //@ts-ignore
                // this.$root.$i18n.locale = (this.$root.$i18n.locale == 'fr' ? 'en' : 'fr');
                window.location.reload();
            },
            toggleDarkTheme(shouldAdd: boolean)
            {
                document.getElementById('buttonToggleModeDark')?.classList.toggle('active', shouldAdd);
                document.getElementById('buttonToggleModeLight')?.classList.toggle('active', !shouldAdd);
                document.body.classList.toggle('dark', shouldAdd);
                localStorage.setItem('dark', shouldAdd ? 'true' : 'false');
                this.isDarkMode = shouldAdd;
            },
            logout()
            {
                //@ts-ignore
                this.$store.dispatch('auth/logout', { }).then(() =>
                    {
                        window.location.href = '/';
                    },
                    //@ts-ignore
                    error =>
                    {
                        var error_msg = (error.response && error.response.data && error.response.data.errors && error.response.data.errors.message) ? error.response.data.errors.message : error.toString();
                        debugger;
                    }
                );
            }
        }
    });
