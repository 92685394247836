import process from 'process';
const WS_URL = process.env.NODE_ENV == 'development' ? 'wss://demo.app.lexoh.com:4001/' : 'wss://' + window.location.hostname + ':4001/';

class SocketService
{
    initialize(app)
    {
        //@ts-ignore
        SocketService.Socket = new WebSocket(WS_URL);
        //@ts-ignore
        SocketService.Socket.onopen = function()
        {
            console.log('CONNECTED');
        };
        //@ts-ignore
        SocketService.Socket.onmessage = function (evt) {
            try
            {
                const json = JSON.parse(evt.data);
                switch(json.Action)
                {
                    case 'ChangeState':
                    {
                        app.$eventBus.emit('device_state_changed', json);
                    }
                    break;
                }
            }
            catch(error)
            {
                console.error(error);
            }
        };
        //@ts-ignore
        SocketService.Socket.onclose = function() {
            localStorage.removeItem('user');
            window.location.href = '/';
        };
    }

    send(data: string)
    {
        //@ts-ignore
        SocketService.Socket.send(data);
    }
}

export default SocketService;
