
    import {
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        modalController,
        toastController,
        alertController,
        IonIcon,
        IonItem,
        IonLabel,
        IonInput,
        IonSelect,
        IonSelectOption
    } from "@ionic/vue";
    import { defineComponent } from "vue";
    import { calendar, personCircle, list, gitNetwork, cog, statsChartOutline } from 'ionicons/icons';
    import { useI18n } from 'vue-i18n';
    import DeviceService from '../services/device.service';
    import ZoneService from '../services/zone.service';
    let translate;
    export default defineComponent({
        name: "DeviceModal",
        components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption },
        props: ['device'],
        data()
        {
            return {
                selectedTab: 1,
                type: '',
                name: '',
                brand: '',
                model: '',
                port_trigger_open: '',
                port_trigger_close: '',
                port_keep_open: '',
                port_signal_open: '',
                port_signal_close: '',
                port_signal_blocked: '',
                port_signal_presence: '',
                keepOpenUntil: '0',
                all_zones: [],
                zone_id: 0,
                barrier_type: 0,
                elka_settings: {
                    stay_open_time: 30,
                    prewarning_before_closing_time: 3,
                    prewarning_before_opening_time: 3,
                    relay_operating_modes: []
                }
            }
        },
        setup() {
            const closeModal = () => {
                modalController.dismiss();
            };
            const { t } = useI18n({
                inheritLocale: true,
                useScope: 'global'
            });
            translate = t;
            return { closeModal, calendar, personCircle, list, gitNetwork, cog, statsChartOutline, t };
        },
        async mounted()
        {
            await this.loadZones();
            for(var i = 0; i < 14; i++) {
                //@ts-ignore
                this.relay_operating_modes[i] = '0';
            }
            this.type                 = this.device.type;
            this.name                 = this.device.name;
            this.brand                = this.device.brand;
            this.model                = this.device.model;
            this.zone_id              = this.device.zone_id == null              ? 0  : this.device.zone_id;
            this.port_trigger_open    = this.device.port_trigger_open == null    ? '' : this.device.port_trigger_open.toString();
            this.port_trigger_close   = this.device.port_trigger_close == null   ? '' : this.device.port_trigger_close.toString();
            this.port_keep_open       = this.device.port_keep_open == null       ? '' : this.device.port_keep_open.toString();
            this.port_signal_open     = this.device.port_signal_open == null     ? '' : this.device.port_signal_open.toString();
            this.port_signal_close    = this.device.port_signal_close == null    ? '' : this.device.port_signal_close.toString();
            this.port_signal_blocked  = this.device.port_signal_blocked == null  ? '' : this.device.port_signal_blocked.toString();
            this.port_signal_presence = this.device.port_signal_presence == null ? '' : this.device.port_signal_presence.toString();
            this.barrier_type         = this.device.barrier_type == null         ? 0  : this.device.barrier_type;
            if (this.device.elka_settings != null) {
                this.elka_settings = JSON.parse(this.device.elka_settings.toString());
            }
        },
        methods: {
            selectTab(index: number)
            {
                this.selectedTab = index;
            },
            async loadZones()
            {
                const response = await ZoneService.getForFilters();
                if (response.count > 0)
                {
                    this.all_zones = response.zones;
                }
            },
            async saveDeviceConfig()
            {
                const response = await DeviceService.editConfig(
                    this.device.id,
                    this.type,
                    this.name,
                    this.brand,
                    this.model,
                    this.zone_id,
                    this.barrier_type,
                    JSON.stringify(this.elka_settings)
                );
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('device_modified'),
                        message: translate('saved_configuration_successfully'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    var message = '';
                    if (typeof response.error != 'undefined') {
                        message = response.error;
                    } else if(typeof response.errors != 'undefined' && typeof response.errors.message != 'undefined') {
                        Object.keys(response.errors.message).forEach(key => {
                            message += response.errors.message[key] + '.<br/>';
                        });
                    }
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('device_modification_error'),
                        message: translate('error') + ': ' + message,
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async openGate()
            {
                const response = await DeviceService.openGate(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async closeGate()
            {
                const response = await DeviceService.closeGate(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_closing'),
                        message: translate('gate_successfully_closing'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_close_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async triggerKeepGateOpen()
            {
                const response = await DeviceService.keepGateOpen(this.device.id, parseInt(this.keepOpenUntil));
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async lostTicket()
            {
                const response = await DeviceService.lostTicket(this.device.id);
                if(response.success)
                {
                    //@ts-ignore
                    this.$eventBus.emit('refresh_devices')
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_opening'),
                        message: translate('gate_successfully_opening'),
                        duration: 2000
                    })
                    return toast.present();
                }
                else
                {
                    const toast = await toastController
                    .create({
                        position: 'top',
                        header: translate('gate_error'),
                        message: translate('cannot_open_gate'),
                        duration: 2000
                    })
                    return toast.present();
                }
            },
            async reboot()
            {
                const alert = await alertController
				.create({
					cssClass: 'my-custom-class',
					header: translate('reboot_device'),
					message: translate('are_you_sure_reboot_device', { name: this.device.name}),
					buttons: [
						{
							text: translate('cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							id: 'cancel-button'
						},
						{
							text: translate('confirm'),
							id: 'confirm-button',
							handler: async () => {
                                const response = await DeviceService.reboot(this.device.id);
                                if(response.success)
                                {
                                    //@ts-ignore
                                    this.$eventBus.emit('refresh_devices')
                                    const toast = await toastController
                                    .create({
                                        position: 'top',
                                        header: translate('gate_rebooting'),
                                        message: translate('gate_successfully_rebooting'),
                                        duration: 2000
                                    })
                                    return toast.present();
                                }
                                else
                                {
                                    const toast = await toastController
                                    .create({
                                        position: 'top',
                                        header: translate('gate_error'),
                                        message: translate('cannot_reboot_gate'),
                                        duration: 2000
                                    })
                                    return toast.present();
                                }
							}
						}
					]
				});
				return alert.present();
            }
        },
        computed: {
            darkMode() {
                return document.body.classList.contains('dark');
            },
            isTechnician() {
                //@ts-ignore
                return this.$store.state.auth.user.role == 1;
            }
        }
    });
