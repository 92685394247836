
	import { defineComponent } from 'vue';
	import { modalController, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
    import { useI18n } from 'vue-i18n';
	import DeviceModal from '@/components/DeviceModal.vue'
    import PayModal from '@/components/PayModal.vue'
    import ZoneService from '@/services/zone.service';
    import PricingService from '@/services/pricing.service';
	export default  defineComponent({
		name: 'PosModal',
		components: { IonItem, IonLabel, IonInput, IonSelect, IonSelectOption },
        props: ['data'],
		setup()
		{
			const closeModal = () => {
				modalController.dismiss();
			};
			const openDevice = async (device: any) => {
				const modal = await modalController.create({
					component: DeviceModal,
					cssClass: 'my-custom-class',
					componentProps: {
						device: device
					}
				});
				return modal.present();
			};
            const openPay = async (big_total, amount_paid, transaction_id) => {
                const modal = await modalController.create({
					component: PayModal,
					cssClass: 'my-custom-class',
					componentProps: {
                        total_amount:   big_total,
                        amount_paid:    amount_paid,
                        transaction_id: transaction_id
					}
				});
				return modal.present();
            };
			const { t } = useI18n({
				inheritLocale: true,
				useScope: 'global'
			});
			return { closeModal, openDevice, openPay, t };
		},
        data()
		{
			return {
                ticket_number       : '',
                entrance_date: '',
                entrance_time: '',
                zone_id      : 0,
                exit_date    : '',
                exit_time    : '',
                all_zones    : [],
                details      : {},
                price        : 0,
                taxes        : [],
                big_total    : 0,
                use_daily_max_rate: false,
                use_weekly_max_rate: false,
                use_monthly_max_rate: false
            }
        },
        async mounted()
        {
            await this.loadZones();
        },
		watch: {
			'ticket_number': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
				},
				deep: true
			},
            'zone_id': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
                    await this.checkCalculation();
				},
				deep: true
			},
            'entrance_date': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
                    await this.checkCalculation();
				},
				deep: true
			},
            'entrance_time': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
                    await this.checkCalculation();
				},
				deep: true
			},
            'exit_date': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
                    await this.checkCalculation();
				},
				deep: true
			},
            'exit_time': {
				handler: async function(newValue) {
					//TODO LOAD ENTRY FROM THIS
                    await this.checkCalculation();
				},
				deep: true
			}
		},
        methods: {
            number_format(n: number, c: number, d: string, t: string)
            {
                c = isNaN(c = Math.abs(c)) ? 2 : c;
                d = d == undefined ? "." : d;
                t = t == undefined ? "," : t;
                var s = n < 0 ? "-" : "",
                //@ts-ignore
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;
                //@ts-ignore
                return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
            },
			async loadZones()
			{
				const response = await ZoneService.getForFilters();
				if (response.count > 0)
				{
					this.all_zones = response.zones;
				}
			},
            async checkCalculation()
            {
                if (this.zone_id > 0 && this.entrance_date != '' && this.entrance_time != '' && this.exit_date != '' && this.exit_time != '') {
                    await this.calculate(this.zone_id, this.entrance_date + ' ' + this.entrance_time, this.exit_date + ' ' + this.exit_time)
                }
            },
            async calculate(zone_id, entrance, exit)
            {
                const response = await PricingService.calculatePricing(zone_id, entrance, exit);
                if (response.success) {
                    this.price                = response.price;
                    this.taxes                = response.taxes;
                    this.big_total            = response.big_total;
                    this.details              = response.details;
                    this.use_daily_max_rate   = response.use_daily_max_rate;
                    this.use_weekly_max_rate  = response.use_weekly_max_rate;
                    this.use_monthly_max_rate = response.use_monthly_max_rate;
                }
            }
        },
		computed: {
			darkMode() {
				return document.body.classList.contains('dark');
			},
			isTechnician() {
				//@ts-ignore
				return this.$store.state.auth.user.role == 1;
			}
		}
	});
