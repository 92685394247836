import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12a29581"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidemenu" }
const _hoisted_2 = { class: "sideMenuOverflow" }
const _hoisted_3 = { class: "sidemenuContent" }
const _hoisted_4 = { class: "sideMenuNavItems" }
const _hoisted_5 = { class: "sideMenuNavItemTooltip" }
const _hoisted_6 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_7 = { class: "sideMenuNavItemTooltip" }
const _hoisted_8 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_9 = { class: "sideMenuNavItemTooltip" }
const _hoisted_10 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_11 = { class: "sideMenuNavItemTooltip" }
const _hoisted_12 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_13 = { class: "sideMenuNavItemTooltip" }
const _hoisted_14 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_15 = { class: "sideMenuNavItemTooltip" }
const _hoisted_16 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_17 = { class: "sideMenuNavItemTooltip" }
const _hoisted_18 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_19 = { class: "sideMenuNavItemTooltip" }
const _hoisted_20 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_21 = { class: "sideMenuNavItemTooltip" }
const _hoisted_22 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_23 = { class: "sideMenuNavItemTooltip" }
const _hoisted_24 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_25 = { class: "sideMenuNavItemTooltip" }
const _hoisted_26 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_27 = { class: "sideMenuNavItemTooltip" }
const _hoisted_28 = { class: "sideMenuNavItemTooltipText" }
const _hoisted_29 = { class: "sideMenuNavItemTooltip" }
const _hoisted_30 = { class: "sideMenuNavItemTooltipText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => {this.$router.push('/');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.pieChartOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('dashboard')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/map')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => {this.$router.push('/map');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.mapOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('map')), 1)
            ])
          ], 2),
          false
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass((this.$route.path == '/pos')?'sideMenuNavItem active':'sideMenuNavItem'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.openPOS();})
              }, [
                _createVNode(_component_ion_icon, { icon: _ctx.cartOutline }, null, 8, ["icon"]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('sale')), 1)
                ])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/cameras')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[3] || (_cache[3] = ($event: any) => {this.$router.push('/cameras');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.videocamOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('cameras')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/devices')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[4] || (_cache[4] = ($event: any) => {this.$router.push('/devices');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.hardwareChipOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('devices')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/customers')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[5] || (_cache[5] = ($event: any) => {this.$router.push('/customers');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.personOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('customers')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/guests')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[6] || (_cache[6] = ($event: any) => {this.$router.push('/guests');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.ticketOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('guests')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/entries')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[7] || (_cache[7] = ($event: any) => {this.$router.push('/entries');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('entries')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/transactions')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[8] || (_cache[8] = ($event: any) => {this.$router.push('/transactions');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.cashOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('transactions')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/statistics')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[9] || (_cache[9] = ($event: any) => {this.$router.push('/statistics');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.statsChartOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('statistics')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/events')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[10] || (_cache[10] = ($event: any) => {this.$router.push('/events');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.calendarOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('events')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/alerts')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[11] || (_cache[11] = ($event: any) => {this.$router.push('/alerts');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.notificationsOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('alerts')), 1)
            ])
          ], 2),
          _createElementVNode("button", {
            class: _normalizeClass((this.$route.path == '/settings')?'sideMenuNavItem active':'sideMenuNavItem'),
            onClick: _cache[12] || (_cache[12] = ($event: any) => {this.$router.push('/settings');})
          }, [
            _createVNode(_component_ion_icon, { icon: _ctx.cogOutline }, null, 8, ["icon"]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('settings')), 1)
            ])
          ], 2)
        ])
      ])
    ])
  ]))
}