// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';

const API_URL = process.env.NODE_ENV == 'development' ? 'https://demo.app.lexoh.com:4000/v1/' : 'https://' + window.location.hostname + ':4000/v1/';

class ConfigService
{
	get(id: string)
	{
		return axios.post(`${API_URL}config`,
		{
			id: id
		}, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

    save(configs: any)
    {
        return axios.patch(`${API_URL}config`, { configs }, { withCredentials: true }).then(response => {
			return response.data;
		});
    }

	list(category: string)
	{
		return axios.post(`${API_URL}config/list`, { category: category }, { withCredentials: true }).then(response => {
			return response.data;
		});
	}

	testMail(hostname: string, port: string, secured: boolean, username: string, password: string, from_name: string, from_email: string, to_email)
	{
		return axios.post(`${API_URL}config/testmail`, { hostname, port, secured, username, password, from_name, from_email, to_email }, { withCredentials: true }).then(response => {
			return response.data;
		});
	}
}

export default new ConfigService()