// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import axios from 'axios';
//@ts-ignore
import process from 'process';

const API_URL = process.env.NODE_ENV == 'development' ? 'https://demo.app.lexoh.com:4000/v1/' : 'https://' + window.location.hostname + ':4000/v1/';

class DeviceService
{
    get(id: string)
    {
        return axios.post(`${API_URL}device`,
        {
            id: id
        }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    list(page: number, search: string)
    {
        return axios.post(`${API_URL}device/list`, {page, search}, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    getDevicesByType(type: string)
    {
        return axios.post(`${API_URL}device/type`, { type }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    insert(type: string, name: string, description: string, hostname: string, port: number, serverurl: string, zone_id: number)
    {
        return axios.put(`${API_URL}device`, {
            type,
            name,
            description,
            hostname,
            port,
            serverurl,
            zone_id
        }, { withCredentials: true }).then(response => {
            return response.data;
        }).catch(function (error) {
            if (error.response)
            {
                return error.response.data;
            }
            return null;
        });
    }

    editConfig(id: number, type: string, name: string, brand: string, model: string, zone_id: number, barrier_type: number, elka_settings: string)
    {
        return axios.patch(`${API_URL}device/config`, {
            id: id.toString(),
            type,
            name,
            brand,
            model,
            zone_id: zone_id.toString(),
            barrier_type,
            elka_settings
        }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    openGate(id: number)
    {
        return axios.post(`${API_URL}device/open`, { id: id.toString() }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    closeGate(id: number)
    {
        return axios.post(`${API_URL}device/close`, { id: id.toString() }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    keepGateOpen(id: number, duration: number)
    {
        return axios.post(`${API_URL}device/keep_open`, { id: id.toString(), duration: duration }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    lostTicket(id: number)
    {
        return axios.post(`${API_URL}device/lost_ticket`, { id: id.toString() }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }

    reboot(id: number)
    {
        return axios.post(`${API_URL}device/reboot`, { id: id.toString() }, { withCredentials: true }).then(response => {
            return response.data;
        });
    }
}

export default new DeviceService()