class Timezone
{
    static getTimezone(user) {
        var timezone = user.timezone;
        if (user.daylight_offset) {
            // Get the current system time in milliseconds
            const now        = new Date().getTime();
            // Convert the system time to a time_t object (time in seconds)
            const time_now   = Math.floor(now / 1000);
            // Get the local time using the time_t object
            const local_time = new Date(time_now * 1000);
            // Determine if daylight saving time is in effect (assuming Montreal's DST starts on the second Sunday of March and ends on the first Sunday of November)
            const is_dst     = local_time.getMonth() > 2 && local_time.getMonth() < 10 && (local_time.getMonth() > 2 || local_time.getDate() >= 14 - local_time.getDay()) && (local_time.getMonth() < 10 || local_time.getDate() <= 7 - local_time.getDay());
            if (is_dst) {
                timezone += user.daylight_offset;
            }

        }
        return timezone;
    }
}

export default Timezone;