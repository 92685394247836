// Copyright 2022 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
//Thermal.js
if(typeof String.toBytes == 'undefined')
{
	String.prototype.toBytes = function()
	{
		var arr = [];
		for (var i = 0; i < this.length; i++)
		{
			arr.push(this[i].charCodeAt(0));
		}
		return arr;
	}
}

if(typeof String.hexToBytes == 'undefined')
{
	String.prototype.hexToBytes = function()
	{
		for (var bytes = [], c = 0; c < this.length; c += 2) bytes.push(parseInt(this.substr(c, 2), 16));
		return bytes;
	}
}

// function hexToBytes(hex) {
// 	for (var bytes = [], c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16));
// 	return bytes;
// }

const EpsonConfig = {
	// Feed control sequences
	CTL_LF: [0x0a], // Print and line feed
	CTL_FF: [0x0c], // Form feed
	CTL_CR: [0x0d], // Carriage return
	CTL_HT: [0x09], // Horizontal tab
	CTL_SET_HT: [0x1b, 0x44], // Set horizontal tab positions
	CTL_VT: [0x1b, 0x64, 0x04], // Vertical tab

	// Printer hardware
	HW_INIT: [0x1b, 0x40], // Clear data in buffer and reset modes
	HW_SELECT: [0x1b, 0x3d, 0x01], // Printer select
	HW_RESET: [0x1b, 0x3f, 0x0a, 0x00], // Reset printer hardware
	BEEP: [0x1b, 0x1e], // Sounds built-in buzzer (if equipped)
	UPSIDE_DOWN_ON: [0x1b, 0x7b, 0x01], // Upside down printing ON (rotated 180 degrees).
	UPSIDE_DOWN_OFF: [0x1b, 0x7b, 0x00], // Upside down printing OFF (default).

	// Cash Drawer
	CD_KICK_2: [0x1b, 0x70, 0x00], // Sends a pulse to pin 2 []
	CD_KICK_5: [0x1b, 0x70, 0x01], // Sends a pulse to pin 5 []

	// Paper
	PAPER_FULL_CUT: [0x1d, 0x56, 0x00], // Full cut paper
	PAPER_PART_CUT: [0x1d, 0x56, 0x01], // Partial cut paper

	// Text format
	TXT_NORMAL: [0x1b, 0x21, 0x00], // Normal text
	TXT_2HEIGHT: [0x1b, 0x21, 0x10], // Double height text
	TXT_2WIDTH: [0x1b, 0x21, 0x20], // Double width text
	TXT_4SQUARE: [0x1b, 0x21, 0x30], // Quad area text
	TXT_UNDERL_OFF: [0x1b, 0x2d, 0x00], // Underline font OFF
	TXT_UNDERL_ON: [0x1b, 0x2d, 0x01], // Underline font 1-dot ON
	TXT_UNDERL2_ON: [0x1b, 0x2d, 0x02], // Underline font 2-dot ON
	TXT_BOLD_OFF: [0x1b, 0x45, 0x00], // Bold font OFF
	TXT_BOLD_ON: [0x1b, 0x45, 0x01], // Bold font ON
	TXT_INVERT_OFF: [0x1d, 0x42, 0x00], // Invert font OFF (eg. white background)
	TXT_INVERT_ON: [0x1d, 0x42, 0x01], // Invert font ON (eg. black background)
	TXT_FONT_A: [0x1b, 0x4d, 0x00], // Font type A
	TXT_FONT_B: [0x1b, 0x4d, 0x01], // Font type B
	TXT_ALIGN_LT: [0x1b, 0x61, 0x00], // Left justification
	TXT_ALIGN_CT: [0x1b, 0x61, 0x01], // Centering
	TXT_ALIGN_RT: [0x1b, 0x61, 0x02], // Right justification

	// All code pages supported by printer.
	CODE_PAGE_PC437_USA: [0x1b, 0x74, 0],
	CODE_PAGE_KATAKANA: [0x1b, 0x74, 1],
	CODE_PAGE_PC850_MULTILINGUAL: [0x1b, 0x74, 2],
	CODE_PAGE_PC860_PORTUGUESE: [0x1b, 0x74, 3],
	CODE_PAGE_PC863_CANADIAN_FRENCH: [0x1b, 0x74, 4],
	CODE_PAGE_PC865_NORDIC: [0x1b, 0x74, 5],
	CODE_PAGE_PC851_GREEK: [0x1b, 0x74, 11],
	CODE_PAGE_PC853_TURKISH: [0x1b, 0x74, 12],
	CODE_PAGE_PC857_TURKISH: [0x1b, 0x74, 13],
	CODE_PAGE_PC737_GREEK: [0x1b, 0x74, 14],
	CODE_PAGE_ISO8859_7_GREEK: [0x1b, 0x74, 15],
	CODE_PAGE_WPC1252: [0x1b, 0x74, 16],
	CODE_PAGE_PC866_CYRILLIC2: [0x1b, 0x74, 17],
	CODE_PAGE_PC852_LATIN2: [0x1b, 0x74, 18],
	CODE_PAGE_SLOVENIA: [0x1b, 0x74, 18],
	CODE_PAGE_PC858_EURO: [0x1b, 0x74, 19],
	CODE_PAGE_KU42_THAI: [0x1b, 0x74, 20],
	CODE_PAGE_TIS11_THAI: [0x1b, 0x74, 21],
	CODE_PAGE_TIS18_THAI: [0x1b, 0x74, 26],
	CODE_PAGE_TCVN3_VIETNAMESE_L: [0x1b, 0x74, 30],
	CODE_PAGE_TCVN3_VIETNAMESE_U: [0x1b, 0x74, 31],
	CODE_PAGE_PC720_ARABIC: [0x1b, 0x74, 32],
	CODE_PAGE_WPC775_BALTIC_RIM: [0x1b, 0x74, 33],
	CODE_PAGE_PC855_CYRILLIC: [0x1b, 0x74, 34],
	CODE_PAGE_PC861_ICELANDIC: [0x1b, 0x74, 35],
	CODE_PAGE_PC862_HEBREW: [0x1b, 0x74, 36],
	CODE_PAGE_PC864_ARABIC: [0x1b, 0x74, 37],
	CODE_PAGE_PC869_GREEK: [0x1b, 0x74, 38],
	CODE_PAGE_ISO8859_2_LATIN2: [0x1b, 0x74, 39],
	CODE_PAGE_ISO8859_15_LATIN9: [0x1b, 0x74, 40],
	CODE_PAGE_PC1098_FARCI: [0x1b, 0x74, 41],
	CODE_PAGE_PC1118_LITHUANIAN: [0x1b, 0x74, 42],
	CODE_PAGE_PC1119_LITHUANIAN: [0x1b, 0x74, 43],
	CODE_PAGE_PC1125_UKRANIAN: [0x1b, 0x74, 44],
	CODE_PAGE_WPC1250_LATIN2: [0x1b, 0x74, 45],
	CODE_PAGE_WPC1251_CYRILLIC: [0x1b, 0x74, 46],
	CODE_PAGE_WPC1253_GREEK: [0x1b, 0x74, 47],
	CODE_PAGE_WPC1254_TURKISH: [0x1b, 0x74, 48],
	CODE_PAGE_WPC1255_HEBREW: [0x1b, 0x74, 49],
	CODE_PAGE_WPC1256_ARABIC: [0x1b, 0x74, 50],
	CODE_PAGE_WPC1257_BALTIC_RIM: [0x1b, 0x74, 51],
	CODE_PAGE_WPC1258_VIETNAMESE: [0x1b, 0x74, 52],
	CODE_PAGE_KZ1048_KAZAKHSTAN: [0x1b, 0x74, 53],
	CODE_PAGE_JAPAN: [0x1b, 0x52, 0x08],
	CODE_PAGE_KOREA: [0x1b, 0x52, 0x0D],
	CODE_PAGE_CHINA: [0x1b, 0x52, 0x0F],
	CODE_PAGE_HK_TW: [0x1b, 0x52, 0x00],
	CODE_PAGE_TCVN_VIETNAMESE: [0x1b, 0x74, 52],

	// Character code pages / iconv name of code table.
	// Only code pages supported by iconv-lite:
	// https://github.com/ashtuchkin/iconv-lite/wiki/Supported-Encodings
	CODE_PAGES: {
		PC437_USA: 'CP437',
		PC850_MULTILINGUAL: 'CP850',
		PC860_PORTUGUESE: 'CP860',
		PC863_CANADIAN_FRENCH: 'CP863',
		PC865_NORDIC: 'CP865',
		PC851_GREEK: 'CP860',
		PC857_TURKISH: 'CP857',
		PC737_GREEK: 'CP737',
		ISO8859_7_GREEK: 'ISO-8859-7',
		WPC1252: 'CP1252',
		PC866_CYRILLIC2: 'CP866',
		PC852_LATIN2: 'CP852',
		SLOVENIA: 'CP852',
		PC858_EURO: 'CP858',
		WPC775_BALTIC_RIM: 'CP775',
		PC855_CYRILLIC: 'CP855',
		PC861_ICELANDIC: 'CP861',
		PC862_HEBREW: 'CP862',
		PC864_ARABIC: 'CP864',
		PC869_GREEK: 'CP869',
		ISO8859_2_LATIN2: 'ISO-8859-2',
		ISO8859_15_LATIN9: 'ISO-8859-15',
		PC1125_UKRANIAN: 'CP1125',
		WPC1250_LATIN2: 'WIN1250',
		WPC1251_CYRILLIC: 'WIN1251',
		WPC1253_GREEK: 'WIN1253',
		WPC1254_TURKISH: 'WIN1254',
		WPC1255_HEBREW: 'WIN1255',
		WPC1256_ARABIC: 'WIN1256',
		WPC1257_BALTIC_RIM: 'WIN1257',
		WPC1258_VIETNAMESE: 'WIN1258',
		KZ1048_KAZAKHSTAN: 'RK1048',
		JAPAN: 'EUC-JP',
		KOREA: 'EUC-KR',
		CHINA: 'EUC-CN',
		HK_TW: 'Big5-HKSCS',
		TCVN_VIETNAMESE: 'tcvn'
	},

	// Barcode format
	BARCODE_TXT_OFF: [0x1d, 0x48, 0x00], // HRI barcode chars OFF
	BARCODE_TXT_ABV: [0x1d, 0x48, 0x01], // HRI barcode chars above
	BARCODE_TXT_BLW: [0x1d, 0x48, 0x02], // HRI barcode chars below
	BARCODE_TXT_BTH: [0x1d, 0x48, 0x03], // HRI barcode chars both above and below
	BARCODE_FONT_A: [0x1d, 0x66, 0x00], // Font type A for HRI barcode chars
	BARCODE_FONT_B: [0x1d, 0x66, 0x01], // Font type B for HRI barcode chars
	BARCODE_HEIGHT: [0x1d, 0x68, 0x64], // Barcode Height [1-255]
	BARCODE_WIDTH: [0x1d, 0x77, 0x03], // Barcode Width  [2-6]
	BARCODE_UPC_A: [0x1d, 0x6b, 0x00], // Barcode type UPC-A
	BARCODE_UPC_E: [0x1d, 0x6b, 0x01], // Barcode type UPC-E
	BARCODE_EAN13: [0x1d, 0x6b, 0x02], // Barcode type EAN13
	BARCODE_EAN8: [0x1d, 0x6b, 0x03], // Barcode type EAN8
	BARCODE_CODE39: [0x1d, 0x6b, 0x04], // Barcode type CODE39
	BARCODE_CODE128: [0x1d, 0x6b, 0x49], // Barcode type CODE128
	BARCODE_ITF: [0x1d, 0x6b, 0x05], // Barcode type ITF
	BARCODE_NW7: [0x1d, 0x6b, 0x06], // Barcode type NW7

	// QR Code
	QRCODE_MODEL1: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x31, 0x00], // Model 1
	QRCODE_MODEL2: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x32, 0x00], // Model 2
	QRCODE_MODEL3: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x33, 0x00], // Model 3

	QRCODE_CORRECTION_L: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x30], // Correction level: L - 7%
	QRCODE_CORRECTION_M: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x31], // Correction level: M - 15%
	QRCODE_CORRECTION_Q: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x32], // Correction level: Q - 25%
	QRCODE_CORRECTION_H: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x33], // Correction level: H - 30%

	QRCODE_CELLSIZE_1: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x01], // Cell size 1
	QRCODE_CELLSIZE_2: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x02], // Cell size 2
	QRCODE_CELLSIZE_3: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x03], // Cell size 3
	QRCODE_CELLSIZE_4: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x04], // Cell size 4
	QRCODE_CELLSIZE_5: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x05], // Cell size 5
	QRCODE_CELLSIZE_6: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x06], // Cell size 6
	QRCODE_CELLSIZE_7: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x07], // Cell size 7
	QRCODE_CELLSIZE_8: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x08], // Cell size 8

	QRCODE_PRINT: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30], // Print QR code

	// PDF417
	PDF417_CORRECTION: [0x1D, 0x28, 0x6B, 0x04, 0x00, 0x30, 0x45, 0x31], // Append 1-40 for ratio
	PDF417_ROW_HEIGHT: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x44], // Append 2-8 for height
	PDF417_WIDTH: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x43], // Append 2-8 for width
	PDF417_COLUMNS: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x41],
	PDF417_OPTION_STANDARD: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x46, 0x00], // Standard barcode
	PDF417_OPTION_TRUNCATED: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x46, 0x01], // Truncated barcode
	PDF417_PRINT: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x51, 0x30],

	// MaxiCode
	// Formatted data containing a structured Carrier Message with a numeric postal code. (US)
	MAXI_MODE2: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x32],
	// Formatted data containing a structured Carrier Message with an alphanumeric postal code. (International)
	MAXI_MODE3: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x33],
	MAXI_MODE4: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x34], // Unformatted data with Standard Error Correction.
	MAXI_MODE5: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x35], // Unformatted data with Enhanced Error Correction.
	MAXI_MODE6: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x36], // For programming hardware devices.
	MAXI_PRINT: [0x1d, 0x28, 0x6B, 0x03, 0x00, 0x32, 0x51, 0x30],

	// Image format
	S_RASTER_N: [0x1d, 0x76, 0x30, 0x00], // Set raster image normal size
	S_RASTER_2W: [0x1d, 0x76, 0x30, 0x01], // Set raster image double width
	S_RASTER_2H: [0x1d, 0x76, 0x30, 0x02], // Set raster image double height
	S_RASTER_Q: [0x1d, 0x76, 0x30, 0x03], // Set raster image quadruple

	// Printing Density
	PD_N50: [0x1d, 0x7c, 0x00], // Printing Density -50%
	PD_N37: [0x1d, 0x7c, 0x01], // Printing Density -37.5%
	PD_N25: [0x1d, 0x7c, 0x02], // Printing Density -25%
	PD_N12: [0x1d, 0x7c, 0x03], // Printing Density -12.5%
	PD_0: [0x1d, 0x7c, 0x04], // Printing Density  0%
	PD_P50: [0x1d, 0x7c, 0x08], // Printing Density +50%
	PD_P37: [0x1d, 0x7c, 0x07], // Printing Density +37.5%
	PD_P25: [0x1d, 0x7c, 0x06], // Printing Density +25%
};

const StarConfig = {
	// Feed control sequences
	CTL_LF: [0x0a], // Print and line feed
	CTL_FF: [0x0c], // Form feed
	CTL_CR: [0x0d], // Carriage return
	CTL_HT: [0x09], // Horizontal tab
	CTL_VT: [0x0b], // Vertical tab
	CTL_SET_HT: [0x1b, 0x44], // Set horizontal tab positions
	CTL_SET_VT: [0x1b, 0x42], // Set vertical tab positions

	// Printer hardware
	HW_INIT: [0x1b, 0x40], // Clear data in buffer and reset modes
	HW_SELECT: [0x1b, 0x3d, 0x01], // Printer select
	HW_RESET: [0x1b, 0x3f, 0x0a, 0x00], // Reset printer hardware
	UPSIDE_DOWN_ON: [0x0F], // Upside down printing ON (rotated 180 degrees).
	UPSIDE_DOWN_OFF: [0x12], // Upside down printing OFF (default).

	// Cash Drawer
	CD_KICK_2: [0x1b, 0x70, 0x00], // Sends a pulse to pin 2 []
	CD_KICK_5: [0x1b, 0x70, 0x01], // Sends a pulse to pin 5 []
	CD_KICK: [0x1b, 0x07, 0x0b, 0x37, 0x07], // Kick the cash drawer

	// Paper
	PAPER_FULL_CUT: [0x1b, 0x64, 0x02], // Full cut paper
	PAPER_PART_CUT: [0x1b, 0x64, 0x03], // Partial cut paper

	// Text format
	TXT_NORMAL: [0x1b, 0x69, 0x00, 0x00], // Normal text
	TXT_2HEIGHT: [0x1b, 0x69, 0x01, 0x00], // Double height text
	TXT_2WIDTH: [0x1b, 0x69, 0x00, 0x01], // Double width text
	TXT_4SQUARE: [0x1b, 0x69, 0x01, 0x01], // Quad area text
	TXT_UNDERL_OFF: [0x1b, 0x2d, 0x00], // Underline font OFF
	TXT_UNDERL_ON: [0x1b, 0x2d, 0x01], // Underline font 1-dot ON
	TXT_UNDERL2_ON: [0x1b, 0x2d, 0x02], // Underline font 2-dot ON
	TXT_BOLD_OFF: [0x1b, 0x46], // Bold font OFF
	TXT_BOLD_ON: [0x1b, 0x45], // Bold font ON
	TXT_INVERT_OFF: [0x1b, 0x35], // Invert font OFF (eg. white background)
	TXT_INVERT_ON: [0x1b, 0x34], // Invert font ON (eg. black background)
	TXT_FONT_A: [0x1b, 0x1e, 0x46, 0x00], // Font type A
	TXT_FONT_B: [0x1b, 0x1e, 0x46, 0x01], // Font type B
	TXT_ALIGN_LT: [0x1b, 0x1d, 0x61, 0x00], // Left justification
	TXT_ALIGN_CT: [0x1b, 0x1d, 0x61, 0x01], // Centering
	TXT_ALIGN_RT: [0x1b, 0x1d, 0x61, 0x02], // Right justification

	// All code pages supported by printer.
	CODE_PAGE_PC437_USA: [0x1b, 0x74, 0],
	CODE_PAGE_KATAKANA: [0x1b, 0x74, 1],
	CODE_PAGE_PC850_MULTILINGUAL: [0x1b, 0x74, 2],
	CODE_PAGE_PC860_PORTUGUESE: [0x1b, 0x74, 3],
	CODE_PAGE_PC863_CANADIAN_FRENCH: [0x1b, 0x74, 4],
	CODE_PAGE_PC865_NORDIC: [0x1b, 0x74, 5],
	CODE_PAGE_PC851_GREEK: [0x1b, 0x74, 11],
	CODE_PAGE_PC853_TURKISH: [0x1b, 0x74, 12],
	CODE_PAGE_PC857_TURKISH: [0x1b, 0x74, 13],
	CODE_PAGE_PC737_GREEK: [0x1b, 0x74, 14],
	CODE_PAGE_ISO8859_7_GREEK: [0x1b, 0x74, 15],
	CODE_PAGE_WPC1252: [0x1b, 0x74, 16],
	CODE_PAGE_PC866_CYRILLIC2: [0x1b, 0x74, 17],
	CODE_PAGE_PC852_LATIN2: [0x1b, 0x74, 18],
	CODE_PAGE_SLOVENIA: [0x1b, 0x74, 18],
	CODE_PAGE_PC858_EURO: [0x1b, 0x74, 19],
	CODE_PAGE_KU42_THAI: [0x1b, 0x74, 20],
	CODE_PAGE_TIS11_THAI: [0x1b, 0x74, 21],
	CODE_PAGE_TIS18_THAI: [0x1b, 0x74, 26],
	CODE_PAGE_TCVN3_VIETNAMESE_L: [0x1b, 0x74, 30],
	CODE_PAGE_TCVN3_VIETNAMESE_U: [0x1b, 0x74, 31],
	CODE_PAGE_PC720_ARABIC: [0x1b, 0x74, 32],
	CODE_PAGE_WPC775_BALTIC_RIM: [0x1b, 0x74, 33],
	CODE_PAGE_PC855_CYRILLIC: [0x1b, 0x74, 34],
	CODE_PAGE_PC861_ICELANDIC: [0x1b, 0x74, 35],
	CODE_PAGE_PC862_HEBREW: [0x1b, 0x74, 36],
	CODE_PAGE_PC864_ARABIC: [0x1b, 0x74, 37],
	CODE_PAGE_PC869_GREEK: [0x1b, 0x74, 38],
	CODE_PAGE_ISO8859_2_LATIN2: [0x1b, 0x74, 39],
	CODE_PAGE_ISO8859_15_LATIN9: [0x1b, 0x74, 40],
	CODE_PAGE_PC1098_FARCI: [0x1b, 0x74, 41],
	CODE_PAGE_PC1118_LITHUANIAN: [0x1b, 0x74, 42],
	CODE_PAGE_PC1119_LITHUANIAN: [0x1b, 0x74, 43],
	CODE_PAGE_PC1125_UKRANIAN: [0x1b, 0x74, 44],
	CODE_PAGE_WPC1250_LATIN2: [0x1b, 0x74, 45],
	CODE_PAGE_WPC1251_CYRILLIC: [0x1b, 0x74, 46],
	CODE_PAGE_WPC1253_GREEK: [0x1b, 0x74, 47],
	CODE_PAGE_WPC1254_TURKISH: [0x1b, 0x74, 48],
	CODE_PAGE_WPC1255_HEBREW: [0x1b, 0x74, 49],
	CODE_PAGE_WPC1256_ARABIC: [0x1b, 0x74, 50],
	CODE_PAGE_WPC1257_BALTIC_RIM: [0x1b, 0x74, 51],
	CODE_PAGE_WPC1258_VIETNAMESE: [0x1b, 0x74, 52],
	CODE_PAGE_KZ1048_KAZAKHSTAN: [0x1b, 0x74, 53],
	CODE_PAGE_JAPAN: [0x1b, 0x52, 0x08],
	CODE_PAGE_KOREA: [0x1b, 0x52, 0x0D],
	CODE_PAGE_CHINA: [0x1b, 0x52, 0x0F],

	// Character code pages / iconv name of code table.
	// Only code pages supported by iconv-lite:
	// https://github.com/ashtuchkin/iconv-lite/wiki/Supported-Encodings
	CODE_PAGES: {
		PC437_USA: 'CP437',
		PC850_MULTILINGUAL: 'CP850',
		PC860_PORTUGUESE: 'CP860',
		PC863_CANADIAN_FRENCH: 'CP863',
		PC865_NORDIC: 'CP865',
		PC851_GREEK: 'CP860',
		PC857_TURKISH: 'CP857',
		PC737_GREEK: 'CP737',
		ISO8859_7_GREEK: 'ISO-8859-7',
		WPC1252: 'CP1252',
		PC866_CYRILLIC2: 'CP866',
		PC852_LATIN2: 'CP852',
		SLOVENIA: 'CP852',
		PC858_EURO: 'CP858',
		WPC775_BALTIC_RIM: 'CP775',
		PC855_CYRILLIC: 'CP855',
		PC861_ICELANDIC: 'CP861',
		PC862_HEBREW: 'CP862',
		PC864_ARABIC: 'CP864',
		PC869_GREEK: 'CP869',
		ISO8859_2_LATIN2: 'ISO-8859-2',
		ISO8859_15_LATIN9: 'ISO-8859-15',
		PC1125_UKRANIAN: 'CP1125',
		WPC1250_LATIN2: 'WIN1250',
		WPC1251_CYRILLIC: 'WIN1251',
		WPC1253_GREEK: 'WIN1253',
		WPC1254_TURKISH: 'WIN1254',
		WPC1255_HEBREW: 'WIN1255',
		WPC1256_ARABIC: 'WIN1256',
		WPC1257_BALTIC_RIM: 'WIN1257',
		WPC1258_VIETNAMESE: 'WIN1258',
		KZ1048_KAZAKHSTAN: 'RK1048',
		JAPAN: 'EUC-JP',
		KOREA: 'EUC-KR',
		CHINA: 'EUC-CN'
	},

	// Barcode format
	BARCODE_TXT_OFF: [0x1d, 0x48, 0x00], // HRI barcode chars OFF
	BARCODE_TXT_ABV: [0x1d, 0x48, 0x01], // HRI barcode chars above
	BARCODE_TXT_BLW: [0x1d, 0x48, 0x02], // HRI barcode chars below
	BARCODE_TXT_BTH: [0x1d, 0x48, 0x03], // HRI barcode chars both above and below
	BARCODE_FONT_A: [0x1d, 0x66, 0x00], // Font type A for HRI barcode chars
	BARCODE_FONT_B: [0x1d, 0x66, 0x01], // Font type B for HRI barcode chars
	BARCODE_HEIGHT: [0x1d, 0x68, 0x64], // Barcode Height [1-255]
	BARCODE_WIDTH: [0x1d, 0x77, 0x03], // Barcode Width  [2-6]
	BARCODE_UPC_A: [0x1d, 0x6b, 0x00], // Barcode type UPC-A
	BARCODE_UPC_E: [0x1d, 0x6b, 0x01], // Barcode type UPC-E
	BARCODE_EAN13: [0x1d, 0x6b, 0x02], // Barcode type EAN13
	BARCODE_EAN8: [0x1d, 0x6b, 0x03], // Barcode type EAN8
	BARCODE_CODE39: [0x1d, 0x6b, 0x04], // Barcode type CODE39
	BARCODE_ITF: [0x1d, 0x6b, 0x05], // Barcode type ITF
	BARCODE_NW7: [0x1d, 0x6b, 0x06], // Barcode type NW7

	BARCODE_CODE128: [0x1b, 0x62, 0x36], // Barcode type CODE128
	BARCODE_CODE128_TEXT_1: [0x01], // No text
	BARCODE_CODE128_TEXT_2: [0x02], // Text on bottom
	BARCODE_CODE128_TEXT_3: [0x03], // No text inline
	BARCODE_CODE128_TEXT_4: [0x04], // Text on bottom inline
	BARCODE_CODE128_WIDTH_SMALL: [0x31], // Small
	BARCODE_CODE128_WIDTH_MEDIUM: [0x32], // Medium
	BARCODE_CODE128_WIDTH_LARGE: [0x33], // Large

	// QR Code
	QRCODE_MODEL1: [0x1b, 0x1d, 0x79, 0x53, 0x30, 0x01], // Model 1
	QRCODE_MODEL2: [0x1b, 0x1d, 0x79, 0x53, 0x30, 0x02], // Model 2

	QRCODE_CORRECTION_L: [0x1b, 0x1d, 0x79, 0x53, 0x31, 0x00], // Correction level: L - 7%
	QRCODE_CORRECTION_M: [0x1b, 0x1d, 0x79, 0x53, 0x31, 0x01], // Correction level: M - 15%
	QRCODE_CORRECTION_Q: [0x1b, 0x1d, 0x79, 0x53, 0x31, 0x02], // Correction level: Q - 25%
	QRCODE_CORRECTION_H: [0x1b, 0x1d, 0x79, 0x53, 0x31, 0x03], // Correction level: H - 30%

	QRCODE_CELLSIZE_1: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x01], // Cell size 1
	QRCODE_CELLSIZE_2: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x02], // Cell size 2
	QRCODE_CELLSIZE_3: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x03], // Cell size 3
	QRCODE_CELLSIZE_4: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x04], // Cell size 4
	QRCODE_CELLSIZE_5: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x05], // Cell size 5
	QRCODE_CELLSIZE_6: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x06], // Cell size 6
	QRCODE_CELLSIZE_7: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x07], // Cell size 7
	QRCODE_CELLSIZE_8: [0x1b, 0x1d, 0x79, 0x53, 0x32, 0x08], // Cell size 8
	QRCODE_CELLSIZE: [0x1b, 0x1d, 0x79, 0x44, 0x31, 0x00], // Cell size nL nH dk

	QRCODE_PRINT: [0x1b, 0x1d, 0x79, 0x50], // Print QR code

	// Image format
	S_RASTER_N: [0x1d, 0x76, 0x30, 0x00], // Set raster image normal size
	S_RASTER_2W: [0x1d, 0x76, 0x30, 0x01], // Set raster image double width
	S_RASTER_2H: [0x1d, 0x76, 0x30, 0x02], // Set raster image double height
	S_RASTER_Q: [0x1d, 0x76, 0x30, 0x03], // Set raster image quadruple

	// Printing Density
	PD_N50: [0x1d, 0x7c, 0x00], // Printing Density -50%
	PD_N37: [0x1d, 0x7c, 0x01], // Printing Density -37.5%
	PD_N25: [0x1d, 0x7c, 0x02], // Printing Density -25%
	PD_N12: [0x1d, 0x7c, 0x03], // Printing Density -12.5%
	PD_0: [0x1d, 0x7c, 0x04], // Printing Density  0%
	PD_P50: [0x1d, 0x7c, 0x08], // Printing Density +50%
	PD_P37: [0x1d, 0x7c, 0x07], // Printing Density +37.5%
	PD_P25: [0x1d, 0x7c, 0x06], // Printing Density +25%
};

const TancaConfig = {
	// Feed control sequences
	CTL_LF: [0x0a], // Print and line feed
	CTL_FF: [0x0c], // Form feed
	CTL_CR: [0x0d], // Carriage return
	CTL_HT: [0x09], // Horizontal tab
	CTL_SET_HT: [0x1b, 0x44], // Set horizontal tab positions
	CTL_VT: [0x1b, 0x64, 0x04], // Vertical tab

	// Printer hardware
	HW_INIT: [0x1b, 0x40], // Clear data in buffer and reset modes
	HW_SELECT: [0x1b, 0x3d, 0x01], // Printer select
	HW_RESET: [0x1b, 0x3f, 0x0a, 0x00], // Reset printer hardware
	BEEP: [0x1B, 0x42, 0x5, 0x1], // Sounds built-in buzzer (if equipped)
	UPSIDE_DOWN_ON: [0x1b, 0x7b, 0x01], // Upside down printing ON (rotated 180 degrees).
	UPSIDE_DOWN_OFF: [0x1b, 0x7b, 0x00], // Upside down printing OFF (default).

	// Cash Drawer
	CD_KICK_2: [0x1b, 0x70, 0x00], // Sends a pulse to pin 2 []
	CD_KICK_5: [0x1b, 0x70, 0x01], // Sends a pulse to pin 5 []

	// Paper
	PAPER_FULL_CUT: [0x1d, 0x56, 0x00], // Full cut paper
	PAPER_PART_CUT: [0x1d, 0x56, 0x01], // Partial cut paper

	// Text format
	TXT_NORMAL: [0x1b, 0x21, 0x00], // Normal text
	TXT_2HEIGHT: [0x1b, 0x21, 0x10], // Double height text
	TXT_2WIDTH: [0x1b, 0x21, 0x20], // Double width text
	TXT_4SQUARE: [0x1b, 0x21, 0x30], // Quad area text
	TXT_UNDERL_OFF: [0x1b, 0x2d, 0x00], // Underline font OFF
	TXT_UNDERL_ON: [0x1b, 0x2d, 0x01], // Underline font 1-dot ON
	TXT_UNDERL2_ON: [0x1b, 0x2d, 0x02], // Underline font 2-dot ON
	TXT_BOLD_OFF: [0x1b, 0x45, 0x00], // Bold font OFF
	TXT_BOLD_ON: [0x1b, 0x45, 0x01], // Bold font ON
	TXT_INVERT_OFF: [0x1d, 0x42, 0x00], // Invert font OFF (eg. white background)
	TXT_INVERT_ON: [0x1d, 0x42, 0x01], // Invert font ON (eg. black background)
	TXT_FONT_A: [0x1b, 0x4d, 0x00], // Font type A
	TXT_FONT_B: [0x1b, 0x4d, 0x01], // Font type B
	TXT_ALIGN_LT: [0x1b, 0x61, 0x00], // Left justification
	TXT_ALIGN_CT: [0x1b, 0x61, 0x01], // Centering
	TXT_ALIGN_RT: [0x1b, 0x61, 0x02], // Right justification

	// All code pages supported by printer.
	CODE_PAGE_PC437_USA: [0x1b, 0x74, 0],
	CODE_PAGE_KATAKANA: [0x1b, 0x74, 1],
	CODE_PAGE_PC850_MULTILINGUAL: [0x1b, 0x74, 2],
	CODE_PAGE_PC860_PORTUGUESE: [0x1b, 0x74, 3],
	CODE_PAGE_PC863_CANADIAN_FRENCH: [0x1b, 0x74, 4],
	CODE_PAGE_PC865_NORDIC: [0x1b, 0x74, 5],
	CODE_PAGE_PC851_GREEK: [0x1b, 0x74, 11],
	CODE_PAGE_PC853_TURKISH: [0x1b, 0x74, 12],
	CODE_PAGE_PC857_TURKISH: [0x1b, 0x74, 13],
	CODE_PAGE_PC737_GREEK: [0x1b, 0x74, 14],
	CODE_PAGE_ISO8859_7_GREEK: [0x1b, 0x74, 15],
	CODE_PAGE_WPC1252: [0x1b, 0x74, 16],
	CODE_PAGE_PC866_CYRILLIC2: [0x1b, 0x74, 17],
	CODE_PAGE_PC852_LATIN2: [0x1b, 0x74, 18],
	CODE_PAGE_SLOVENIA: [0x1b, 0x74, 18],
	CODE_PAGE_PC858_EURO: [0x1b, 0x74, 19],
	CODE_PAGE_KU42_THAI: [0x1b, 0x74, 20],
	CODE_PAGE_TIS11_THAI: [0x1b, 0x74, 21],
	CODE_PAGE_TIS18_THAI: [0x1b, 0x74, 26],
	CODE_PAGE_TCVN3_VIETNAMESE_L: [0x1b, 0x74, 30],
	CODE_PAGE_TCVN3_VIETNAMESE_U: [0x1b, 0x74, 31],
	CODE_PAGE_PC720_ARABIC: [0x1b, 0x74, 32],
	CODE_PAGE_WPC775_BALTIC_RIM: [0x1b, 0x74, 33],
	CODE_PAGE_PC855_CYRILLIC: [0x1b, 0x74, 34],
	CODE_PAGE_PC861_ICELANDIC: [0x1b, 0x74, 35],
	CODE_PAGE_PC862_HEBREW: [0x1b, 0x74, 36],
	CODE_PAGE_PC864_ARABIC: [0x1b, 0x74, 37],
	CODE_PAGE_PC869_GREEK: [0x1b, 0x74, 38],
	CODE_PAGE_ISO8859_2_LATIN2: [0x1b, 0x74, 39],
	CODE_PAGE_ISO8859_15_LATIN9: [0x1b, 0x74, 40],
	CODE_PAGE_PC1098_FARCI: [0x1b, 0x74, 41],
	CODE_PAGE_PC1118_LITHUANIAN: [0x1b, 0x74, 42],
	CODE_PAGE_PC1119_LITHUANIAN: [0x1b, 0x74, 43],
	CODE_PAGE_PC1125_UKRANIAN: [0x1b, 0x74, 44],
	CODE_PAGE_WPC1250_LATIN2: [0x1b, 0x74, 45],
	CODE_PAGE_WPC1251_CYRILLIC: [0x1b, 0x74, 46],
	CODE_PAGE_WPC1253_GREEK: [0x1b, 0x74, 47],
	CODE_PAGE_WPC1254_TURKISH: [0x1b, 0x74, 48],
	CODE_PAGE_WPC1255_HEBREW: [0x1b, 0x74, 49],
	CODE_PAGE_WPC1256_ARABIC: [0x1b, 0x74, 50],
	CODE_PAGE_WPC1257_BALTIC_RIM: [0x1b, 0x74, 51],
	CODE_PAGE_WPC1258_VIETNAMESE: [0x1b, 0x74, 52],
	CODE_PAGE_KZ1048_KAZAKHSTAN: [0x1b, 0x74, 53],
	CODE_PAGE_JAPAN: [0x1b, 0x52, 0x08],
	CODE_PAGE_KOREA: [0x1b, 0x52, 0x0D],
	CODE_PAGE_CHINA: [0x1b, 0x52, 0x0F],
	CODE_PAGE_HK_TW: [0x1b, 0x52, 0x00],

	// Character code pages / iconv name of code table.
	// Only code pages supported by iconv-lite:
	// https://github.com/ashtuchkin/iconv-lite/wiki/Supported-Encodings
	CODE_PAGES: {
		PC437_USA: 'CP437',
		PC850_MULTILINGUAL: 'CP850',
		PC860_PORTUGUESE: 'CP860',
		PC863_CANADIAN_FRENCH: 'CP863',
		PC865_NORDIC: 'CP865',
		PC851_GREEK: 'CP860',
		PC857_TURKISH: 'CP857',
		PC737_GREEK: 'CP737',
		ISO8859_7_GREEK: 'ISO-8859-7',
		WPC1252: 'CP1252',
		PC866_CYRILLIC2: 'CP866',
		PC852_LATIN2: 'CP852',
		SLOVENIA: 'CP852',
		PC858_EURO: 'CP858',
		WPC775_BALTIC_RIM: 'CP775',
		PC855_CYRILLIC: 'CP855',
		PC861_ICELANDIC: 'CP861',
		PC862_HEBREW: 'CP862',
		PC864_ARABIC: 'CP864',
		PC869_GREEK: 'CP869',
		ISO8859_2_LATIN2: 'ISO-8859-2',
		ISO8859_15_LATIN9: 'ISO-8859-15',
		PC1125_UKRANIAN: 'CP1125',
		WPC1250_LATIN2: 'WIN1250',
		WPC1251_CYRILLIC: 'WIN1251',
		WPC1253_GREEK: 'WIN1253',
		WPC1254_TURKISH: 'WIN1254',
		WPC1255_HEBREW: 'WIN1255',
		WPC1256_ARABIC: 'WIN1256',
		WPC1257_BALTIC_RIM: 'WIN1257',
		WPC1258_VIETNAMESE: 'WIN1258',
		KZ1048_KAZAKHSTAN: 'RK1048',
		JAPAN: 'EUC-JP',
		KOREA: 'EUC-KR',
		CHINA: 'EUC-CN',
		HK_TW: 'Big5-HKSCS'
	},

	// Barcode format
	BARCODE_TXT_OFF: [0x1d, 0x48, 0x00], // HRI barcode chars OFF
	BARCODE_TXT_ABV: [0x1d, 0x48, 0x01], // HRI barcode chars above
	BARCODE_TXT_BLW: [0x1d, 0x48, 0x02], // HRI barcode chars below
	BARCODE_TXT_BTH: [0x1d, 0x48, 0x03], // HRI barcode chars both above and below
	BARCODE_FONT_A: [0x1d, 0x66, 0x00], // Font type A for HRI barcode chars
	BARCODE_FONT_B: [0x1d, 0x66, 0x01], // Font type B for HRI barcode chars
	BARCODE_HEIGHT: [0x1d, 0x68, 0x64], // Barcode Height [1-255]
	BARCODE_WIDTH: [0x1d, 0x77, 0x03], // Barcode Width  [2-6]
	BARCODE_UPC_A: [0x1d, 0x6b, 0x00], // Barcode type UPC-A
	BARCODE_UPC_E: [0x1d, 0x6b, 0x01], // Barcode type UPC-E
	BARCODE_EAN13: [0x1d, 0x6b, 0x02], // Barcode type EAN13
	BARCODE_EAN8: [0x1d, 0x6b, 0x03], // Barcode type EAN8
	BARCODE_CODE39: [0x1d, 0x6b, 0x04], // Barcode type CODE39
	BARCODE_ITF: [0x1d, 0x6b, 0x05], // Barcode type ITF
	BARCODE_NW7: [0x1d, 0x6b, 0x06], // Barcode type NW7

	// QR Code
	QRCODE_MODEL1: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x31, 0x00], // Model 1
	QRCODE_MODEL2: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x32, 0x00], // Model 2
	QRCODE_MODEL3: [0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x33, 0x00], // Model 3

	QRCODE_CORRECTION_L: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x30], // Correction level: L - 7%
	QRCODE_CORRECTION_M: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x31], // Correction level: M - 15%
	QRCODE_CORRECTION_Q: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x32], // Correction level: Q - 25%
	QRCODE_CORRECTION_H: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x33], // Correction level: H - 30%

	QRCODE_CELLSIZE_1: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x01], // Cell size 1
	QRCODE_CELLSIZE_2: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x02], // Cell size 2
	QRCODE_CELLSIZE_3: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x03], // Cell size 3
	QRCODE_CELLSIZE_4: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x04], // Cell size 4
	QRCODE_CELLSIZE_5: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x05], // Cell size 5
	QRCODE_CELLSIZE_6: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x06], // Cell size 6
	QRCODE_CELLSIZE_7: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x07], // Cell size 7
	QRCODE_CELLSIZE_8: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x08], // Cell size 8

	QRCODE_PRINT: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x30], // Print QR code

	// PDF417
	PDF417_CORRECTION: [0x1D, 0x28, 0x6B, 0x04, 0x00, 0x30, 0x45, 0x31], // Append 1-40 for ratio
	PDF417_ROW_HEIGHT: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x44], // Append 2-8 for height
	PDF417_WIDTH: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x43], // Append 2-8 for width
	PDF417_COLUMNS: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x41],
	PDF417_OPTION_STANDARD: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x46, 0x00], // Standard barcode
	PDF417_OPTION_TRUNCATED: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x46, 0x01], // Truncated barcode
	PDF417_PRINT: [0x1D, 0x28, 0x6B, 0x03, 0x00, 0x30, 0x51, 0x30],

	// MaxiCode
	// Formatted data containing a structured Carrier Message with a numeric postal code. (US)
	MAXI_MODE2: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x32],
	// Formatted data containing a structured Carrier Message with an alphanumeric postal code. (International)
	MAXI_MODE3: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x33],
	MAXI_MODE4: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x34], // Unformatted data with Standard Error Correction.
	MAXI_MODE5: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x35], // Unformatted data with Enhanced Error Correction.
	MAXI_MODE6: [0x1d, 0x28, 0x6b, 0x03, 0x00, 0x32, 0x41, 0x36], // For programming hardware devices.
	MAXI_PRINT: [0x1d, 0x28, 0x6B, 0x03, 0x00, 0x32, 0x51, 0x30],

	// Image format
	S_RASTER_N: [0x1d, 0x76, 0x30, 0x00], // Set raster image normal size
	S_RASTER_2W: [0x1d, 0x76, 0x30, 0x01], // Set raster image double width
	S_RASTER_2H: [0x1d, 0x76, 0x30, 0x02], // Set raster image double height
	S_RASTER_Q: [0x1d, 0x76, 0x30, 0x03], // Set raster image quadruple

	// Printing Density
	PD_N50: [0x1d, 0x7c, 0x00], // Printing Density -50%
	PD_N37: [0x1d, 0x7c, 0x01], // Printing Density -37.5%
	PD_N25: [0x1d, 0x7c, 0x02], // Printing Density -25%
	PD_N12: [0x1d, 0x7c, 0x03], // Printing Density -12.5%
	PD_0: [0x1d, 0x7c, 0x04], // Printing Density  0%
	PD_P50: [0x1d, 0x7c, 0x08], // Printing Density +50%
	PD_P37: [0x1d, 0x7c, 0x07], // Printing Density +37.5%
	PD_P25: [0x1d, 0x7c, 0x06], // Printing Density +25%
};

var EpsonPrinter = (function()
{
	var printer = function(){ this.config = EpsonConfig; };
	printer.prototype.getConfig = function() { return this.config; }
	printer.prototype.append = function(appendBuffer)
	{
		if (this.buffer) {
			this.buffer = this.buffer.concat(typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer]);
		} else {
			this.buffer = typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer];
		}
	}
	printer.prototype.beep = function() { return this.config.BEEP; }
	printer.prototype.setTextSize = function(height, width)
	{
		this.buffer = null;
		if (height > 7 || height < 0) throw new Error('setTextSize: Height must be between 0 and 7');
		if (width  > 7 || width  < 0) throw new Error('setTextSize: Width must be between 0 and 7');
		const x = `${height}${width}`.hexToBytes();
		this.append([0x1D, 0x21]);
		this.append(x);
		return this.buffer;
	}
	printer.prototype.printQR = function(str, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// [Name] Select the QR code model
		// [Code] 1D 28 6B 04 00 31 41 n1 n2
		// n1
		// [49 x31, model 1]
		// [50 x32, model 2]
		// [51 x33, micro qr code]
		// n2 = 0
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=140
		if (settings.model)
		{
			if (settings.model === 1) this.append(this.config.QRCODE_MODEL1);
			else if (settings.model === 3) this.append(this.config.QRCODE_MODEL3);
			else this.append(this.config.QRCODE_MODEL2);
		} else {
			this.append(this.config.QRCODE_MODEL2);
		}

		// [Name]: Set the size of module
		// 1D 28 6B 03 00 31 43 n
		// n depends on the printer
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=141
		if (settings.cellSize) {
			const i = 'QRCODE_CELLSIZE_'.concat(settings.cellSize.toString());
			this.append(this.config[i]);
		} else {
			this.append(this.config.QRCODE_CELLSIZE_3);
		}

		// [Name] Select the error correction level
		// 1D 28 6B 03 00 31 45 n
		// n
		// [48 x30 -> 7%]
		// [49 x31-> 15%]
		// [50 x32 -> 25%]
		// [51 x33 -> 30%]
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=142
		if (settings.correction) {
			const i = 'QRCODE_CORRECTION_'.concat(settings.correction.toUpperCase());
			this.append(this.config[i]);
		} else {
			this.append(this.config.QRCODE_CORRECTION_M);
		}

		// [Name] Store the data in the symbol storage area
		// 1D 28  6B pL pH 31 50 30 d1...dk
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=143
		const s = str.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x31, 0x50, 0x30]);
		this.append(str.toBytes());

		// [Name] Print the symbol data in the symbol storage area
		// 1D 28 6B 03 00 31 51 m
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=144
		this.append(this.config.QRCODE_PRINT);

		return this.buffer;
	}
	printer.prototype.pdf417 = function(data, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Set error correction ratio 1 - 40
		if (settings.correction) {
			this.append(this.config.PDF417_CORRECTION);
			this.append([settings.correction]);
		} else {
			this.append(this.config.PDF417_CORRECTION);
			this.append([0x01]);
		}

		// Set row height 2 - 8
		if (settings.rowHeight) {
			this.append(this.config.PDF417_ROW_HEIGHT);
			this.append([settings.rowHeight]);
		} else {
			this.append(this.config.PDF417_ROW_HEIGHT);
			this.append([0x03]);
		}

		// Set width of module 2 - 8
		if (settings.width) {
			this.append(this.config.PDF417_WIDTH);
			this.append([settings.width]);
		} else {
			this.append(this.config.PDF417_WIDTH);
			this.append([0x03]);
		}

		// Manually set columns 1 - 30
		if (settings.columns) {
			this.append(this.config.PDF417_COLUMNS);
			this.append([settings.columns]);
		} else {
			// Default to auto
			this.append(this.config.PDF417_COLUMNS);
			this.append([0x00]);
		}

		// Standard or truncated option
		if (settings.truncated) this.append(this.config.PDF417_OPTION_TRUNCATED);
		else this.append(this.config.PDF417_OPTION_STANDARD);

		// Set PDF417 bar code data
		const s = data.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);

		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x30, 0x50, 0x30]);
		this.append(data.toString().toBytes());

		// Print barcode
		this.append(this.config.PDF417_PRINT);

		return this.buffer;
	}
	printer.prototype.maxiCode = function(data, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Maxi Mode
		// 2 - Formatted data/structured Carrier Message with a numeric postal code. (US)
		// 3 - Formatted data/structured Carrier Message with a numeric postal code. (International)
		// 4 - Unformatted data/Standard Error Correction.
		// 5 - Unformatted data/Enhanced Error Correction.
		// 6 - Used for programming hardware devices.
		if (settings.mode) {
			if (settings.mode == 2) this.append(this.config.MAXI_MODE2);
			else if (settings.mode == 3) this.append(this.config.MAXI_MODE3);
			else if (settings.mode == 5) this.append(this.config.MAXI_MODE5);
			else if (settings.mode == 6) this.append(this.config.MAXI_MODE6);
			else this.append(this.config.MAXI_MODE4);
		} else {
			this.append(this.config.MAXI_MODE4);
		}

		// Setup size of MaxiCode data
		const s = data.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);

		// Send Data
		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x32, 0x50, 0x30]);
		this.append(data.toString().toBytes());

		// Print barcode
		this.append(this.config.MAXI_PRINT);

		return this.buffer;
	}
	printer.prototype.code128 = function(data, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Set HRI characters Position, 0-3 (none, top, bottom, top/bottom)
		if (settings.hriPos) {
			this.append([0x1d, 0x48]); // GS H
			this.append([settings.hriPos]);
		} else {
			this.append([0x1d, 0x48, 0x00]);
		}
		// Set HRI character font. 0-4, 48-52, 97, 98 (depending on printer, 0 and 1 available on all), default 0
		if (settings.hriFont) {
			this.append([0x1d, 0x66]); // GS f
			this.append([settings.hriFont]);
		} else {
			this.append([0x1d, 0x66, 0x00]);
		}
		// Set width 2-6, default 3
		if (settings.width) {
			this.append([0x1d, 0x77]); // GS W
			this.append([settings.width]);
		} else {
			this.append([0x1d, 0x77, 0x03]);
		}
		// Set height 1 - 255 default 162
		if (settings.height) {
			this.append([0x1d, 0x68]); // GS h
			this.append([settings.height]);
		} else {
			this.append([0x1d, 0x68, 0xA2]);
		}
		// Print Barcode
		this.append(this.config.BARCODE_CODE128);
		this.append([data.length + 2]);
		this.append([0x7b, 0x42]);
		// Data
		this.append(data.toBytes());
		return this.buffer;
	}
	printer.prototype.printBarcode = function(data, type, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Set HRI characters Position, 0-3 (none, top, bottom, top/bottom)
		if (settings.hriPos) {
			this.append([0x1d, 0x48]); // GS H
			this.append([settings.hriPos]);
		} else {
			this.append([0x1d, 0x48, 0x00]);
		}
		// Set HRI character font. 0-4, 48-52, 97, 98 (depending on printer, 0 and 1 available on all), default 0
		if (settings.hriFont) {
			this.append([0x1d, 0x66]); // GS f
			this.append([settings.hriFont]);
		} else {
			this.append([0x1d, 0x66, 0x00]);
		}
		// Set width 2-6, default 3
		if (settings.width) {
			this.append([0x1d, 0x77]); // GS W
			this.append([settings.width]);
		} else {
			this.append([0x1d, 0x77, 0x03]);
		}
		// Set height 1 - 255 default 162
		if (settings.height) {
			this.append([0x1d, 0x68]); // GS h
			this.append([settings.height]);
		} else {
			this.append([0x1d, 0x68, 0xA2]);
		}
		// Print Barcode
		this.append([0x1d, 0x6b]); // GS k
		// Select type and bit length of data
		if(type == 73)
		{
			this.append([type, data.length + 2]);
			this.append([0x7b, 0x42]);
		}
		else
		{
			this.append([type, data.length]);
		}
		// Data
		this.append(data.toBytes());
		return this.buffer;
	}
	//https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=88
	printer.prototype.printImageBuffer = function(width, height, data)
	{
		this.buffer = null;
		// Get pixel rgba in 2D array
		const pixels = [];
		for (let i = 0; i < height; i++)
		{
			const line = [];
			for (let j = 0; j < width; j++)
			{
				const idx = (width * i + j) << 2;
				line.push({
                    r: data[idx],
                    g: data[idx + 1],
                    b: data[idx + 2],
                    a: data[idx + 3],
				});
			}
			pixels.push(line);
		}
		const imageBufferArray = [];
		for (let i = 0; i < height; i++)
		{
			for (let j = 0; j < Math.ceil(width / 8); j++)
			{
				let byte = 0x0;
				for (let k = 0; k < 8; k++) {
					let pixel = pixels[i][j * 8 + k];
					// Image overflow
					if (pixel === undefined)
					{
						pixel = {
							a: 0,
							r: 0,
							g: 0,
							b: 0
						};
					}
					if (pixel.a > 126) { // checking transparency
						const grayscale = parseInt(0.2126 * pixel.r + 0.7152 * pixel.g + 0.0722 * pixel.b);
						if (grayscale < 128) { // checking color
							const mask = 1 << 7 - k; // setting bitwise mask
							byte |= mask; // setting the correct bit to 1
						}
					}
				}
				imageBufferArray.push(byte);
				// imageBuffer = imageBuffer.concat([byte]);
			}
		}
		const imageBuffer = imageBufferArray;
		// Print raster bit image
		// GS v 0
		// 1D 76 30 m xL xH yL yH d1...dk
		// xL = (this.width >> 3) & 0xff;
		// xH = 0x00;
		// yL = this.height & 0xff;
		// yH = (this.height >> 8) & 0xff;
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=94
		// Check if width/8 is decimal
		if (width % 8 != 0) {
			width += 8;
		}
		this.append([0x1d, 0x76, 0x30, 48]);
		this.append([(width >> 3) & 0xff]);
		this.append([0x00]);
		this.append([height & 0xff]);
		this.append([(height >> 8) & 0xff]);
		// append data
		this.append(imageBuffer);
		return this.buffer;
	}
	return printer;
})();

var StarPrinter = (function()
{
	var printer = function(){ this.config = StarConfig; };
	printer.prototype.getConfig = function() { return this.config; }
	printer.prototype.append = function(appendBuffer)
	{
		if (this.buffer) {
			this.buffer = this.buffer.concat(typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer]);
		} else {
			this.buffer = typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer];
		}
	}
	printer.prototype.beep = function()
	{
		//Not implemented...
	}
	printer.prototype.setTextSize = function(height, width)
	{
		//Not implemented...
	}
	printer.prototype.printQR = function(str, settings)
	{
		this.buffer = null;
		if (!settings) {
			settings = {};
		}
		const config = {
			model: this.config.QRCODE_MODEL1,
			correctionLevel: this.config.QRCODE_CORRECTION_M,
			cellSize: this.config.QRCODE_CELLSIZE_4
		};
		const models = {
			1: this.config.QRCODE_MODEL1,
			2: this.config.QRCODE_MODEL2
		};
		const correctionLevels = {
			L: this.config.QRCODE_CORRECTION_L, // Correction level: L - 7%
			M: this.config.QRCODE_CORRECTION_M, // Correction level: M - 15%
			Q: this.config.QRCODE_CORRECTION_Q, // Correction level: Q - 25%
			H: this.config.QRCODE_CORRECTION_H // Correction level: H - 30%
		};
		const cellSizes = {
			1: this.config.QRCODE_CELLSIZE_1, // Cell size 1
			2: this.config.QRCODE_CELLSIZE_2, // Cell size 2
			3: this.config.QRCODE_CELLSIZE_3, // Cell size 3
			4: this.config.QRCODE_CELLSIZE_4, // Cell size 4
			5: this.config.QRCODE_CELLSIZE_5, // Cell size 5
			6: this.config.QRCODE_CELLSIZE_6, // Cell size 6
			7: this.config.QRCODE_CELLSIZE_7, // Cell size 7
			8: this.config.QRCODE_CELLSIZE_8 // Cell size 8
		};
		if (models[settings.model]) config.model = models[settings.model];
		if (correctionLevels[settings.correctionLevel]) config.correctionLevel = correctionLevels[settings.correctionLevel];
		if (cellSizes[settings.cellSize]) config.cellSize = cellSizes[settings.cellSize];
		// [Name] Set QR code model
		// [Code] Hex. 1B 1D 79 53 30 n
		// [Defined Area] 1 ≤ n ≤ 2
		// [Initial Value] n = 2
		// [Function] Sets the model.
		//  • Parameter details
		// n | Set Model
		// ---+---------------
		// 1 | Model 1
		// 2 | Model 2
		this.append(config.model);
		// [Name] Set QR code mistake correction level
		// [Code] Hex. 1B 1D 79 53 31 n
		// [Defined Area] 0 ≤ n ≤ 3
		// [Initial Value] n = 0
		// [Function] Sets the mistake correction level.
		//  • Parameter details
		// n | Correction Level | Mistake Correction Rate (%)
		// --+------------------+----------------------------
		// 0 | L | 7
		// 1 | M | 15
		// 2 | Q | 25
		// 3 | H | 30
		this.append(config.correctionLevel);
		// [Name] Set QR code cell size
		// [Code] Hex. 1B 1D 79 53 32 n
		// [Defined Area] 1 ≤ n ≤ 8
		// [Initial Value] n = 3
		// [Function] Sets the cell size.
		//  • Parameter details
		//  • n: Cell size (Units: Dots)
		//  • It is recommended that the specification using this command be 3 ≤ n.
		//    If n = 1 or 2, check by actually using.
		this.append(config.cellSize);
		// [Name] Set QR code cell size (Auto Setting)
		// [Code] Hex. 1B 1D 79 44 31 m nL nH d1 d2 … dk
		// [Defined Area]
		// m = 0
		// 0 ≤ nL ≤ 255,
		// 0 ≤ nH ≤ 255
		// 1 ≤ nL + nH x 256 ≤ 7089 (k = nL + nH x 256)
		// 0 ≤ d ≤ 255
		// [Function]
		// Automatically expands the data type of the bar code and sets the data.
		//  • Parameter details
		//  • nL + nH x 256: Byte count of bar code data
		//  • dk: Bar code data (Max. 7089 bytes)
		//  • When using this command, the printer receives data for the number of bytes (k) specified by nL and nH.
		//    The data automatically expands to be set as the qr code data.
		//  • Indicates the number bytes of data specified by the nL and nH. Bar code data is cleared at this time.
		//  • The data storage region of this command is shared with the manual setting command so data is updated
		//    each time either command is executed.
		const s = str.length;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		this.append([lsb, msb]); // nL, nH
		this.append(str.toString().toBytes()); // Data
		this.append([0x0a]); // NL (new line)
		// [Name] Print QR code
		// [Code] 1B 1D 79 50
		// [Function] Prints bar code data.
		// When receiving this command, if there is unprinted data in the image buffer,
		// the printer will print the bar code after printing the unprinted print data.
		// A margin of more than 4 cells is required around the QR code. The user should ensure that space.
		// Always check printed bar codes in actual use.
		this.append(this.config.QRCODE_PRINT);
		return this.buffer;
	}
	printer.prototype.pdf417 = function(data, settings)
	{
		this.buffer = null;
		if (settings) {
			throw new Error('PDF417 settings not yet available for star printers!');
		}
		// (1) Bar code type setting (<ESC> <GS> “x” “S”)
		// (2) Bar code data setting (<ESC> <GS> “x” “D”)
		// (3) Bar code printing (<ESC> <GS> “x” “P”)
		// (4) Bar code expansion information acquisition (<ESC> <GS> “x” “I”)
		// Set PDF417 bar code size
		// 1B 1D 78 53 30 n p1 p2
		this.append([0x1b, 0x1d, 0x78, 0x53, 0x30, 0x00, 0x01, 0x02]);
		// Set PDF417 ECC (security level)
		// 1B 1D 78 53 31 n
		this.append([0x1b, 0x1d, 0x78, 0x53, 0x31, 0x02]);
		// Set PDF417 module X direction size
		// 1B 1D 78 53 32 n
		this.append([0x1b, 0x1d, 0x78, 0x53, 0x32, 0x02]);
		// Set PDF417 module aspect ratio
		// 1B 1D 78 53 33 n
		this.append([0x1b, 0x1d, 0x78, 0x53, 0x33, 0x03]);
		// Set PDF417 bar code data
		// 1B 1D 78 44 nL nH d1 d2 … dk
		const s = data.length;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		this.append([0x1b, 0x1d, 0x78, 0x44]);
		this.append([lsb, msb]); // nL, nH
		this.append(data.toString().toBytes()); // Data
		this.append([0x0a]); // NL (new line)
		// Print PDF417 bar code
		// 1B 1D 78 50
		this.append([0x1b, 0x1d, 0x78, 0x50]);
		return this.buffer;
	}
	printer.prototype.maxiCode = function(data, settings)
	{
		//Not implemented...
	}
	printer.prototype.code128 = function(data, settings)
	{
		this.buffer = null;
		this.append(this.config.BARCODE_CODE128);
		// Barcode option
		// 1 - No text
		// 2 - Text on bottom
		// 3 - No text inline
		// 4 - Text on bottom inline
		if (settings) {
			if (settings.text == 1) this.append(this.config.BARCODE_CODE128_TEXT_1);
			else if (settings.text == 2) this.append(this.config.BARCODE_CODE128_TEXT_2);
			else if (settings.text == 3) this.append(this.config.BARCODE_CODE128_TEXT_3);
			else if (settings.text == 4) this.append(this.config.BARCODE_CODE128_TEXT_4);
		} else {
			this.append(this.config.BARCODE_CODE128_TEXT_2);
		}
		// Barcode width
		// 31 - Small
		// 32 - Medium
		// 33 - Large
		if (settings) {
			if (settings.width == 'SMALL') this.append(this.config.BARCODE_CODE128_WIDTH_SMALL);
			else if (settings.width == 'MEDIUM') this.append(this.config.BARCODE_CODE128_WIDTH_MEDIUM);
			else if (settings.width == 'LARGE') this.append(this.config.BARCODE_CODE128_WIDTH_LARGE);
		} else {
			this.append(this.config.BARCODE_CODE128_WIDTH_LARGE);
		}
		// Barcode height
		if (settings && settings.height) this.append([settings.height]);
		else this.append([0x50]);
		// Barcode data
		this.append(data.toString().toBytes());
		// Append RS(record separator)
		this.append([0x1e]);
		return this.buffer;
	}
	printer.prototype.printBarcode = function(data, type, settings)
	{
		this.buffer = null;
		if (!settings) {
			settings = {};
		}
		// [Name] ESC b n1 n2 n3 n4 d1...dk RS
		// [Code] Hex. 1B 62 n1 n2 n3 n4 d1 ... dk 1E
		// [Defined Area]
		//   n1 (Barcode type): 0≤n1≤8, 48≤n1≤56 (0≤n1≤8)
		//   n2 (Barcode under-bar): 1≤n2≤4, 49≤n2≤52 (1≤n2≤4)
		//   n3 (Barcode mode),
		//   n4 (Barcode height) 1≤n4≤255
		//   d  (Barcode data),
		//   k  (Barcode data count) definitions differ according to the type of barcode.
		//   RS
		// [Function]
		//   Barcode printing is executed according to the following parameters.
		//   If n1, n2, n3 and n4 are acquired and detected to be out of the defined area, data up to RS is discarded.
		this.append([0x1b, 0x62]);
		// n1 - Barcode type selection
		// +-----------------------+
		// | n1    | Barcode type  |
		// |-----------------------|
		// | 0, 48 | UPC-E         |
		// | 1, 49 | UPC-A         |
		// | 2, 50 | JAN/EAN8      |
		// | 3, 51 | JAN/EAN13     |
		// | 4, 52 | Code39        |
		// | 5, 53 | ITF           |
		// | 6, 54 | Code128       |
		// | 7, 55 | Code93        |
		// | 8, 56 | NW-7          |
		// +-----------------------+
		this.append([type || 7]);
		// n2 - Under-bar character selection and added line feed selection
		// +--------------------------------------------------------------------------------------------+
		// | n2    | Selection                                                                          |
		// |--------------------------------------------------------------------------------------------|
		// | 1, 49 | No added under-bar charactersExecutes line feed after printing a bar code          |
		// | 2, 50 | Adds under-bar characters Executes line feed after printing a bar code             |
		// | 3, 51 | No added under-bar charactersDoes not execute line feed after printing a bar code  |
		// | 4, 52 | Adds under-bar characters Does not execute line feed after printing a bar code     |
		// +--------------------------------------------------------------------------------------------+
		this.append([settings.characters || 1]);
		// n3 - Barcode mode selection
		// +-------------------------------------------------------------------------------------------+
		// | n3    |                                   Bar code type                                   |
		// |       +-----------------------------------------------------------------------------------+
		// |       | UPC-E, UPC-A, JAN/EAN8     | Code39, NW-7             | ITF                       |
		// |       | JAN/EAN13, Code128, Code93 |                          |                           |
		// +-------------------------------------------------------------------------------------------+
		// | 1, 49 | Minimum module 2 dots      | Narrow: Wide = 2:6 dots  | Narrow: Wide = 2:5 dots   |
		// | 2, 50 | Minimum module 3 dots      | Narrow: Wide = 3:9 dots  | Narrow: Wide = 4:10 dots  |
		// | 3, 51 | Minimum module 4 dots      | Narrow: Wide = 4:12 dots | Narrow: Wide = 6:15 dots  |
		// | 4, 52 | - - -                      | Narrow: Wide = 2:5 dots  | Narrow: Wide = 2:4 dots   |
		// | 5, 53 | - - -                      | Narrow: Wide = 3:8 dots  | Narrow: Wide = 4:8 dots   |
		// | 6, 54 | - - -                      | Narrow: Wide = 4:10 dots | Narrow: Wide = 6:12 dots  |
		// | 7, 55 | - - -                      | Narrow: Wide = 2:4 dots  | Narrow: Wide = 2:6 dots   |
		// | 8, 56 | - - -                      | Narrow: Wide = 3:6 dots  | Narrow: Wide = 3:9 dots   |
		// | 9, 57 | - - -                      | Narrow: Wide = 4:8 dots  | Narrow: Wide = 4:12 dots  |
		// +-------------------------------------------------------------------------------------------+
		this.append([settings.mode || 2]);
		// n4 - Barcode height (dot count)
		// +-------------------------------------------------------------------------------------------------------+
		// | Specification A                                | Specification B                                      |
		// +-------------------------------------------------------------------------------------------------------+
		// | When the height of the bar code is more than   | Form feed at (Bar code height + underbar characters) |
		// | the form feed amount, the form feed amount is  |                                                      |
		// | automatically doubled.                         |                                                      |
		// +-------------------------------------------------------------------------------------------------------+
		this.append([settings.height || 150]);
		// d - Barcode data
		// +----------------------------------------------------------------------------------------------------------------+
		// | Bar code type | Defined area of k             | Defined area of d                                              |
		// +----------------------------------------------------------------------------------------------------------------+
		// | UPC-E         | 11≤k≤12                       | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// | UPC-A         | 11≤k≤12                       | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// | JAN/EAN8      | 7≤k≤8                         | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// | JAN/EAN13     | 12≤k≤13                       | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// | Code39        | 1≤k                           | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// |               |                               | 65≤d≤90 (”A”≤d≤”Z”)                                            |
		// |               |                               | 32, 36, 37, 43, 45, 46, 47 (SP, ”$”, ”%”, ”+”, ”-“, ”.”, ”/”)  |
		// | ITF           | 1≤k                           | 48≤d≤57 (“0”≤d≤”9”)                                           |
		// |               | When an odd number: 0 is      |                                                                |
		// |               | automatically applied to the  |                                                                |
		// |               | top.                          |                                                                |
		// | Code128       | 1≤k                           | 0≤d≤127                                                        |
		// | Code93        | 1≤k                           | 0≤d≤127                                                        |
		// | NW-7          | 1≤k                           | 48≤d≤57 (”0”≤d≤”9”)                                            |
		// |               |                               | 65≤d≤68 (”A”≤d≤”D”)                                            |
		// |               |                               | 36, 43, 45, 46, 47, 58 (”$”, ”+”, ”-“, ”.”, ”/”, ”:”)          |
		// |               |                               | 97, 98, 99, 100 (”a”, ”b”, ”c”, ”d”)                           |
		// +----------------------------------------------------------------------------------------------------------------+
		this.append(data.toBytes());
		// k - Barcode data count
		// • UPC – E: k = 11 (or 12)
		//   The 12th check digit is automatically applied, so it is specified and ignored.
		//   The command is ignored for data that cannot be shortened.
		//   Automatically converts data to shortened form.
		//
		// • UPC – A: k = 11 (or 12)
		//   The 12th check digit is automatically applied, so it is specified and ignored.
		//
		// • JAN/EAN – 8: k = 7 (or 8)
		//   The 8th check digit is automatically applied, so it is specified and ignored.
		//
		// • JAN/EAN -13: k = 12 (or 13)
		//   The 13th check digit cannot be automatically applied, so it is specified and ignored.
		//
		// • CODE 39: k is freely set, and maximum value differs according to the mode.
		//   Start/stop code (“*”) is automatically applied.
		//
		// • ITF: k is freely set, and maximum value differs according to the mode.
		//   If data is oddly numbered, a 0 is applied to the top.
		//
		// • CODE 128: k is freely set, and maximum value differs according to the mode and the print character type.
		//   The check character is automatically applied.
		//
		// • CODE 93: k is freely set, and maximum value differs according to the mode and the print character type.
		//   The check character (“□”) is automatically applied.
		//
		// • NW7: k is freely set, and maximum value differs according to the mode and the print character type.

		// NOTE: Not needed.
		// this.append([data.length]);

		// Start/stop codes included in the data (not automatically applied).
		this.append([0x1e]);
		return this.buffer;
	}
	//https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=88
	printer.prototype.printImageBuffer = function(width, height, data)
	{
		this.buffer = null;
		// Get pixel rgba in 2D array
		const pixels = [];
		for (let i = 0; i < height; i++) {
			const line = [];
			for (let j = 0; j < width; j++) {
				const idx = (width * i + j) << 2;
				line.push({
					r: data[idx],
					g: data[idx + 1],
					b: data[idx + 2],
					a: data[idx + 3]
				});
			}
			pixels.push(line);
		}
		this.append([0x1b, 0x30]);
		// v3
		for (let i = 0; i < Math.ceil(height / 24); i++) {
			let imageBuffer = [];
			for (let y = 0; y < 24; y++) {
				for (let j = 0; j < Math.ceil(width / 8); j++) {
					let byte = 0x0;
					for (let x = 0; x < 8; x++) {
						if ((i * 24 + y < pixels.length) && (j * 8 + x < pixels[i * 24 + y].length)) {
						const pixel = pixels[i * 24 + y][j * 8 + x];
						if (pixel.a > 126) { // checking transparency
							const grayscale = parseInt(0.2126 * pixel.r + 0.7152 * pixel.g + 0.0722 * pixel.b);

							if (grayscale < 128) { // checking color
							const mask = 1 << 7 - x; // setting bitwise mask
							byte |= mask; // setting the correct bit to 1
							}
						}
						}
					}
					imageBuffer = imageBuffer.concat([byte]);
				}
			}
			this.append([0x1b, 0x6b, parseInt(imageBuffer.length / 24), 0x00]);
			this.append(imageBuffer);
			this.append('\n'.toBytes());
		}
		this.append([0x1b, 0x7a, 0x01]);
		return this.buffer;
	}
	return printer;
})();

var TancaPrinter = (function()
{
	var printer = function(){ this.config = TancaConfig; };
	printer.prototype.getConfig = function() { return this.config; }
	printer.prototype.append = function(appendBuffer)
	{
		if (this.buffer) {
			this.buffer = this.buffer.concat(typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer]);
		} else {
			this.buffer = typeof appendBuffer == 'object' ? appendBuffer : [appendBuffer];
		}
	}
	printer.prototype.beep = function() { return this.config.BEEP; }
	printer.prototype.setTextSize = function(height, width)
	{
		this.buffer = null;
		if (height > 7 || height < 0) throw new Error('setTextSize: Height must be between 0 and 7');
		if (width > 7 || width < 0) throw new Error('setTextSize: Width must be between 0 and 7');
		const x = `${height}${width}`.hexToBytes();
		this.append([0x1D, 0x21]);
		this.append(x);
		return this.buffer;
	}
	printer.prototype.printQR = function(str, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// [Name] Select the QR code model
		// [Code] 1D 28 6B 04 00 31 41 n1 n2
		// n1
		// [49 x31, model 1]
		// [50 x32, model 2]
		// [51 x33, micro qr code]
		// n2 = 0
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=140
		if (settings.model) {
			if (settings.model === 1) this.append(this.config.QRCODE_MODEL1);
			else if (settings.model === 3) this.append(this.config.QRCODE_MODEL3);
			else this.append(this.config.QRCODE_MODEL2);
		} else {
			this.append(this.config.QRCODE_MODEL2);
		}
		// [Name]: Set the size of module
		// 1D 28 6B 03 00 31 43 n
		// n depends on the printer
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=141
		if (settings.cellSize) {
			const i = 'QRCODE_CELLSIZE_'.concat(settings.cellSize.toString());
			this.append(this.config[i]);
		} else {
			this.append(this.config.QRCODE_CELLSIZE_3);
		}
		// [Name] Select the error correction level
		// 1D 28 6B 03 00 31 45 n
		// n
		// [48 x30 -> 7%]
		// [49 x31-> 15%]
		// [50 x32 -> 25%]
		// [51 x33 -> 30%]
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=142
		if (settings.correction) {
			const i = 'QRCODE_CORRECTION_'.concat(settings.correction.toUpperCase());
			this.append(this.config[i]);
		} else {
			this.append(this.config.QRCODE_CORRECTION_M);
		}
		// [Name] Store the data in the symbol storage area
		// 1D 28  6B pL pH 31 50 30 d1...dk
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=143
		const s = str.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x31, 0x50, 0x30]);
		this.append(str.toBytes());
		// [Name] Print the symbol data in the symbol storage area
		// 1D 28 6B 03 00 31 51 m
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=144
		this.append(this.config.QRCODE_PRINT);
		return this.buffer;
	}
	printer.prototype.pdf417 = function(data, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Set error correction ratio 1 - 40
		if (settings.correction) {
			this.append(this.config.PDF417_CORRECTION);
			this.append([settings.correction]);
		} else {
			this.append(this.config.PDF417_CORRECTION);
			this.append([0x01]);
		}
		// Set row height 2 - 8
		if (settings.rowHeight) {
			this.append(this.config.PDF417_ROW_HEIGHT);
			this.append([settings.rowHeight]);
		} else {
			this.append(this.config.PDF417_ROW_HEIGHT);
			this.append([0x03]);
		}
		// Set width of module 2 - 8
		if (settings.width) {
			this.append(this.config.PDF417_WIDTH);
			this.append([settings.width]);
		} else {
			this.append(this.config.PDF417_WIDTH);
			this.append([0x03]);
		}
		// Manually set columns 1 - 30
		if (settings.columns) {
			this.append(this.config.PDF417_COLUMNS);
			this.append([settings.columns]);
		} else {
			// Default to auto
			this.append(this.config.PDF417_COLUMNS);
			this.append([0x00]);
		}
		// Standard or truncated option
		if (settings.truncated) this.append(this.config.PDF417_OPTION_TRUNCATED);
		else this.append(this.config.PDF417_OPTION_STANDARD);
		// Set PDF417 bar code data
		const s = data.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x30, 0x50, 0x30]);
		this.append(data.toString().toBytes());
		// Print barcode
		this.append(this.config.PDF417_PRINT);
		return this.buffer;
	}
	printer.prototype.maxiCode = function(data, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Maxi Mode
		// 2 - Formatted data/structured Carrier Message with a numeric postal code. (US)
		// 3 - Formatted data/structured Carrier Message with a numeric postal code. (International)
		// 4 - Unformatted data/Standard Error Correction.
		// 5 - Unformatted data/Enhanced Error Correction.
		// 6 - Used for programming hardware devices.
		if (settings.mode) {
			if (settings.mode == 2) this.append(this.config.MAXI_MODE2);
			else if (settings.mode == 3) this.append(this.config.MAXI_MODE3);
			else if (settings.mode == 5) this.append(this.config.MAXI_MODE5);
			else if (settings.mode == 6) this.append(this.config.MAXI_MODE6);
			else this.append(this.config.MAXI_MODE4);
		} else {
			this.append(this.config.MAXI_MODE4);
		}
		// Setup size of MaxiCode data
		const s = data.length + 3;
		const lsb = parseInt(s % 256);
		const msb = parseInt(s / 256);
		// Send Data
		this.append([0x1d, 0x28, 0x6b, lsb, msb, 0x32, 0x50, 0x30]);
		this.append(data.toString().toBytes());
		// Print barcode
		this.append(this.config.MAXI_PRINT);
		return this.buffer;
	}
	printer.prototype.code128 = function(data, settings)
	{
		//Not implemented...
	}
	printer.prototype.printBarcode = function(data, type, settings)
	{
		this.buffer = null;
		settings = settings || {};
		// Set HRI characters Position, 0-3 (none, top, bottom, top/bottom)
		if (settings.hriPos) {
			this.append([0x1d, 0x48]); // GS H
			this.append([settings.hriPos]);
		} else {
			this.append([0x1d, 0x48, 0x00]);
		}
		// Set HRI character font. 0-4, 48-52, 97, 98 (depending on printer, 0 and 1 available on all), default 0
		if (settings.hriFont) {
			this.append([0x1d, 0x66]); // GS f
			this.append([settings.hriFont]);
		} else {
			this.append([0x1d, 0x66, 0x00]);
		}
		// Set width 2-6, default 3
		if (settings.width) {
			this.append([0x1d, 0x77]); // GS W
			this.append([settings.width]);
		} else {
			this.append([0x1d, 0x77, 0x03]);
		}
		// Set height 1 - 255 default 162
		if (settings.height) {
			this.append([0x1d, 0x68]); // GS h
			this.append([settings.height]);
		} else {
			this.append([0x1d, 0x68, 0xA2]);
		}
		// Print Barcode
		this.append([0x1d, 0x6b]); // GS k
		// Select type and bit length of data
		this.append([type, data.length]);
		// Data
		this.append(data.toBytes());
		return this.buffer;
	}
	//https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=88
	printer.prototype.printImageBuffer = function(width, height, data)
	{
		this.buffer = null;
		// Get pixel rgba in 2D array
		const pixels = [];
		for (let i = 0; i < height; i++) {
			const line = [];
			for (let j = 0; j < width; j++) {
				const idx = (width * i + j) << 2;
				line.push({
					r: data[idx],
					g: data[idx + 1],
					b: data[idx + 2],
					a: data[idx + 3]
				});
			}
			pixels.push(line);
		}
		const imageBufferArray = [];
		for (let i = 0; i < height; i++) {
			for (let j = 0; j < Math.ceil(width / 8); j++) {
				let byte = 0x0;
				for (let k = 0; k < 8; k++) {
					let pixel = pixels[i][j * 8 + k];
					// Image overflow
					if (pixel === undefined) {
						pixel = {
							a: 0,
							r: 0,
							g: 0,
							b: 0
						};
					}
					if (pixel.a > 126) { // checking transparency
						const grayscale = parseInt(0.2126 * pixel.r + 0.7152 * pixel.g + 0.0722 * pixel.b);
						if (grayscale < 128) { // checking color
							const mask = 1 << 7 - k; // setting bitwise mask
							byte |= mask; // setting the correct bit to 1
						}
					}
				}
				imageBufferArray.push(byte);
				// imageBuffer = imageBuffer.concat([byte]);
			}
		}
		const imageBuffer = imageBufferArray;
		// Print raster bit image
		// GS v 0
		// 1D 76 30 m xL xH yL yH d1...dk
		// xL = (this.width >> 3) & 0xff;
		// xH = 0x00;
		// yL = this.height & 0xff;
		// yH = (this.height >> 8) & 0xff;
		// https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=94
		// Check if width/8 is decimal
		if (width % 8 != 0) {
			width += 8;
		}
		this.append([0x1d, 0x76, 0x30, 48]);
		this.append([(width >> 3) & 0xff]);
		this.append([0x00]);
		this.append([height & 0xff]);
		this.append([(height >> 8) & 0xff]);
		// append data
		this.append(imageBuffer);
		return this.buffer;
	}
	return printer;
})();

var ThermalPrinter = (function()
{
	var api = function(printerName, config)
	{
		this.buffer     = null;
		this.config     = null;
		this.printer    = null;
		this.configName = '';
		if(printerName.toLowerCase().includes('tanca') && !printerName.toLowerCase().includes('epson'))
		{
			this.printer = new TancaPrinter();
			this.configName = 'tanca';
		}
		else if(printerName.toLowerCase().includes('star') && !printerName.toLowerCase().includes('epson'))
		{
			this.printer = new StarPrinter();
			this.configName = 'star';
		}
		else
		{
			this.printer = new EpsonPrinter();
			this.configName = 'epson';
		}
		this.config = {
			width: config.width || 42,
			characterSet: 'PC863_CANADIAN_FRENCH',
			lineCharacter: '-'
		};
		this.setCharacterSet(this.config.characterSet);
	};
	
	api.prototype.getOutput = function()
	{
		// _data = _data.concat([0x00A]);//Line feed
		// _data = _data.concat([0x00A]);//Line feed
		// _data = _data.concat([0x01B, 0x064, 2]);//End print
		// _data = _data.concat([0x01D, 0x056, 1]);//Cut paper full
		// const buffer = new Uint8Array(_data).buffer;
		// _data = [];
		// return buffer;
		return new Uint8Array(this.buffer).buffer;
	}

	api.prototype.cut = function()
	{
		this.append(this.printer.config.CTL_VT);
		this.append(this.printer.config.CTL_VT);
		this.append(this.printer.config.PAPER_FULL_CUT);
		this.append(this.printer.config.HW_INIT);
	}

	api.prototype.partialCut = function()
	{
		this.append(this.printer.config.CTL_VT);
		this.append(this.printer.config.CTL_VT);
		this.append(this.printer.config.PAPER_PART_CUT);
		this.append(this.printer.config.HW_INIT);
	}

	api.prototype.getWidth = function()
	{
		return parseInt(this.config.width);
	}

	api.prototype.getText = function()
	{
		return this.buffer.toString();
	}

	api.prototype.getBuffer = function()
	{
		return this.buffer;
	}

	api.prototype.setBuffer = function(newBuffer)
	{
		this.buffer = newBuffer;
	}

	api.prototype.clear = function()
	{
		this.buffer = null;
	}

	api.prototype.add = function(buffer)
	{
		this.append(buffer);
	}

	api.prototype.print = function(text)
	{
		text = text || '';
		this.append(text.toString());
	}

	api.prototype.println = function(text)
	{
		this.print(text);
		this.append('\n');
	}

	api.prototype.printVerticalTab = function()
	{
		this.append(this.printer.config.CTL_VT);
	}

	api.prototype.bold = function(enabled)
	{
		if (enabled) this.append(this.printer.config.TXT_BOLD_ON);
		else this.append(this.printer.config.TXT_BOLD_OFF);
	}

	api.prototype.underline = function(enabled)
	{
		if (enabled) this.append(this.printer.config.TXT_UNDERL_ON);
		else this.append(this.printer.config.TXT_UNDERL_OFF);
	}

	api.prototype.underlineThick = function(enabled)
	{
		if (enabled) this.append(this.printer.config.TXT_UNDERL2_ON);
		else this.append(this.printer.config.TXT_UNDERL_OFF);
	}

	api.prototype.upsideDown = function(enabled)
	{
		if (enabled) this.append(this.printer.config.UPSIDE_DOWN_ON);
		else this.append(this.printer.config.UPSIDE_DOWN_OFF);
	}

	api.prototype.invert = function(enabled)
	{
		if (enabled) this.append(this.printer.config.TXT_INVERT_ON);
		else this.append(this.printer.config.TXT_INVERT_OFF);
	}

	api.prototype.openCashDrawer = function()
	{
		if (this.configName == 'star') {
			this.append(this.printer.config.CD_KICK);
		} else {
			this.append(this.printer.config.CD_KICK_2);
			this.append(this.printer.config.CD_KICK_5);
		}
	}

	api.prototype.alignCenter = function()
	{
		this.append(this.printer.config.TXT_ALIGN_CT);
	}

	api.prototype.alignLeft = function()
	{
		this.append(this.printer.config.TXT_ALIGN_LT);
	}

	api.prototype.alignRight = function()
	{
		this.append(this.printer.config.TXT_ALIGN_RT);
	}

	api.prototype.setTypeFontA = function()
	{
		this.append(this.printer.config.TXT_FONT_A);
	}

	api.prototype.setTypeFontB = function()
	{
		this.append(this.printer.config.TXT_FONT_B);
	}

	api.prototype.setTextNormal = function()
	{
		this.append(this.printer.config.TXT_NORMAL);
	}

	api.prototype.setTextDoubleHeight = function()
	{
		this.append(this.printer.config.TXT_2HEIGHT);
	}

	api.prototype.setTextDoubleWidth = function()
	{
		this.append(this.printer.config.TXT_2WIDTH);
	}

	api.prototype.setTextQuadArea = function()
	{
		this.append(this.printer.config.TXT_4SQUARE);
	}

	api.prototype.setTextSize = function(height, width)
	{
		this.append(this.printer.setTextSize(height, width));
	}

	api.prototype.newLine = function()
	{
		this.append(this.printer.config.CTL_LF);
	}

	api.prototype.drawLine = function()
	{
		// this.newLine();
		for (let i = 0; i < this.config.width; i++)
		{
			this.append(this.config.lineCharacter);
		}
		this.newLine();
	}

	api.prototype.leftRight = function(left, right)
	{
		this.append(left.toString());
		const width = this.config.width - left.toString().length - right.toString().length;
		for (let i = 0; i < width; i++) {
			this.append(' '.toBytes());
		}
		this.append(right.toString());
	}

	api.prototype.table = function(data)
	{
		const cellWidth = this.config.width / data.length;
		for (let i = 0; i < data.length; i++) {
			this.append(data[i].toString());
			const spaces = cellWidth - data[i].toString().length;
			for (let j = 0; j < spaces; j++) {
				this.append(' '.toBytes());
			}
		}
		this.newLine();
	}

	api.prototype.tableCustom = function(data)
	{
		let cellWidth = this.config.width / data.length;
		const secondLine = [];
		let secondLineEnabled = false;
		for (let i = 0; i < data.length; i++) {
			let tooLong = false;
			const obj = data[i];
			obj.text = obj.text.toString();
			if (obj.width) {
				cellWidth = this.config.width * obj.width;
			} else if (obj.cols) {
				cellWidth = obj.cols;
			}
			if (obj.bold) {
				this.bold(true);
			}
			// If text is too wide go to next line
			if (cellWidth < obj.text.length) {
				tooLong = true;
				obj.originalText = obj.text;
				obj.text = obj.text.substring(0, cellWidth - 1);
			}
			if (obj.align == 'CENTER') {
				const spaces = (cellWidth - obj.text.toString().length) / 2;
				for (let j = 0; j < spaces; j++) {
					this.append(' '.toBytes());
				}
				if (obj.text != '') this.append(obj.text);
				for (let j = 0; j < spaces - 1; j++) {
					this.append(' '.toBytes());
				}
			} else if (obj.align == 'RIGHT') {
				const spaces = cellWidth - obj.text.toString().length;
				for (let j = 0; j < spaces; j++) {
					this.append(' '.toBytes());
				}
				if (obj.text != '') this.append(obj.text);
			} else {
				if (obj.text != '') this.append(obj.text);
				const spaces = cellWidth - obj.text.toString().length;
				for (let j = 0; j < spaces; j++) {
					this.append(' '.toBytes());
				}
			}
			if (obj.bold) {
				this.bold(false);
			}
			if (tooLong) {
				secondLineEnabled = true;
				obj.text = obj.originalText.substring(cellWidth - 1);
				secondLine.push(obj);
			} else {
				obj.text = '';
				secondLine.push(obj);
			}
		}
		this.newLine();
		// Print the second line
		if (secondLineEnabled) {
			this.tableCustom(secondLine);
		}
	}

	api.prototype.beep = function()
	{
		this.append(this.printer.beep());
	}

	api.prototype.printQR = function(data, settings)
	{
		this.append(this.printer.printQR(data, settings));
	}

	api.prototype.printBarcode = function(data, type, settings)
	{
		this.append(this.printer.printBarcode(data, type, settings));
	}

	api.prototype.maxiCode = function(data, settings)
	{
		this.append(this.printer.maxiCode(data, settings));
	}

	api.prototype.code128 = function(data, settings)
	{
		this.append(this.printer.code128(data, settings));
	}

	api.prototype.pdf417 = function(data, settings)
	{
		this.append(this.printer.pdf417(data, settings));
	}

    api.prototype.createImageElement = function(file)
    {
        return new Promise (function (resolved, rejected) {
            var i = new Image()
            i.onload = function(){
                resolved({w: i.width, h: i.height, img: i})
            };
            i.src = file
        })
    }

    api.prototype.getImageData = function(image)
    {
        const canvas = document.createElement("canvas");
        canvas.width = image.w;
        canvas.height = image.h;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image.img, 0, 0);
        const imgData = ctx.getImageData(0, 0, image.w, image.h);
        canvas.remove();
        return imgData.data;
    }

	api.prototype.printImage = async function(url)
	{
        if (url.slice(-4) === '.png' || url.startsWith('data:image/png;')) {
            const image = await this.createImageElement(url);
            const buff = this.printer.printImageBuffer(image.w, image.h, this.getImageData(image));
            this.append(buff);
            return buff;
        } else {
            throw new Error('Image printing supports only PNG files.');
        }
	}

	api.prototype.setCharacterSet = function(characterSet)
	{
		const buffer = this.printer.config[`CODE_PAGE_${characterSet}`];
		if (buffer) {
			this.append(buffer);
			this.config.codePage = characterSet;
		} else {
			throw new Error(`Code page not recognized: '${characterSet}'`);
		}
	}

	api.prototype.append = function(text)
	{
		if (typeof text === 'string')
		{
			let endBuff = null;
			for (const char of text) {
				let code = char;
				//@ts-ignore
				// if (!/^[\x00-\x7F]$/.test(char)) {
				// 	code = '';//Handle special character
				// }
				code = typeof code == 'string' ? code.toBytes() : code;
				endBuff = endBuff ? [...endBuff, ...code] : code;
			}
			text = endBuff;
		}
		// Append buffer
		if (text) {
			if (this.buffer) {
				this.buffer = this.buffer.concat(typeof text == 'string' ? text.toBytes() : text);
			} else {
				this.buffer = typeof text == 'string' ? text.toBytes() : text;
			}
		}
	}

	return api;
})();

export default ThermalPrinter;
