import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"

if (typeof navigator != 'undefined' && 'serviceWorker' in navigator && typeof navigator.serviceWorker != 'undefined') {
    navigator.serviceWorker.register('firebase-messaging-sw.js').then(reg => {
        console.log(`Service Worker Registration (Scope: ${reg.scope})`);
    })
    .catch(error => {
        const msg = `Service Worker Error (${error})`;
        console.error(msg);
    });
} else {
    // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
    console.warn('Service Worker not available');
}

const app = initializeApp({
    apiKey           : "AIzaSyCCKtdwlIKuSnzKc6HpQgeaBgVW6Y4GRe0",
    authDomain       : "lexohparking.firebaseapp.com",
    projectId        : "lexohparking",
    storageBucket    : "lexohparking.appspot.com",
    messagingSenderId: "379170442916",
    appId            : "1:379170442916:web:e3e113a691fb96f7012031",
    measurementId    : "G-815XX7BSC5"
});

const messaging = getMessaging(app);
getToken(messaging, { vapidKey: 'BHG0EiF0HP-ohuDpYvGhDAgMJgWgbO4z6DTN2fCVNnJ4paL1QaCh62cQcy9RR53SrVKwfKHMetth1GFPow_-KEU' }).then((currentToken) => {
    if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        console.log({currentToken})
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

export default messaging;
