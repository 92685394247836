// Copyright 2023 Lexoh Inc. All rights reserved.
// NOTICE: All information contained herein is, and remains the property of
// Lexoh Inc. The intellectual and technical concepts contained herein are
// proprietary to Lexoh Inc and may be covered by Canada and Foreign Patents, patents
// in process, and are protected by trade secret or copyright law. Dissemination
// of this information or reproduction of this material is strictly forbidden
// unless prior written permission is obtained from Lexoh Inc. Access to the source
// code contained herein is hereby forbidden to anyone except current Lexoh Inc
// employees, managers or contractors who have executed Confidentiality and
// Non-disclosure agreements explicitly covering such access.
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: () => import('@/views/DashboardPage.vue')
	},
	{
		path: '/map/',
		component: () => import('@/views/MapPage.vue')
	},
	{
		path: '/cameras/',
		component: () => import('@/views/CamerasPage.vue')
	},
	{
		path: '/devices/',
		component: () => import('@/views/DevicesPage.vue')
	},
	{
		path: '/customers/',
		component: () => import('@/views/CustomersPage.vue')
	},
	{
		path: '/guests/',
		component: () => import('@/views/GuestsPage.vue')
	},
	{
		path: '/entries/',
		component: () => import('@/views/EntriesPage.vue')
	},
	{
		path: '/statistics/',
		component: () => import('@/views/StatisticsPage.vue')
	},
	{
		path: '/events/',
		component: () => import('@/views/EventsPage.vue')
	},
	{
		path: '/transactions/',
		component: () => import('@/views/TransactionsPage.vue')
	},
	{
		path: '/alerts/',
		component: () => import('@/views/AlertsPage.vue')
	},
	{
		path: '/settings/',
		component: () => import('@/views/SettingsPage.vue')
	},
	{
		path: '/settings/:selectedPage',
		component: () => import('@/views/SettingsPage.vue'),
		props: true
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router